import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import "./Viewjobs.css";
import { CSVLink } from "react-csv";
import HttpClient from "../../components/HttpClient";
import { useLocation } from "react-router-dom";
import { useScrollTrigger } from "@mui/material";
import toast from "react-hot-toast";
import { IoMdDownload } from 'react-icons/io';
import DocViewer from "react-doc-viewer";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useStateContext } from "../../contexts/ContextProvider";
import moment from 'moment'
import { DateRangePicker } from "react-date-range";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import DataTable from "react-data-table-component";


const Loader = () => {
  return (
    <div style={{ position: 'fixed', top: '25%', left: '40%', zIndex: 9999, fontSize: "25px", marginLeft: "20px" }}>
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

const INITIAl = {
  jobTitle: '',
  designation: '',
  employmentType: '',
  keySkills: "",
  department: '',
  workMode: '',
  jobLocation: "",
  experience: '',
  salary: '',
  industryType: '',
  education: "",
};

const ViewAllJobReport = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [apiEnd, setApiEnd] = useState({});
  const [jobDetail, setJobDetails] = useState(INITIAl);
  const [filteredJobsList, setFilteredJobsList] = useState([]);

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cvData, setCvData] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [jobsData, setJobsData] = useState([])
  const [jobsListData, setJobsListData] = useState([])
  const { userProfileData } = useStateContext();
  const [selectedDate, setSelectedDate] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [isShowPicker, setIsShowPicker] = useState(false);
  const [accountManagers, setAccountManagers] = useState([]);
  const [allRecruiter, setRecruiter] = useState([]);
  const [accManager, setAccManager] = useState("");
  const [recData, setRecData] = useState("");
  const [AcoountManagerData, setAccountManagerData] = useState([]);
  const [singleJob, setSingleJOb] = useState([])


  console.log("Acoount4565ManagerData", jobsListData)
  // setRecruiter

  const columns = [
    {
      name: (
        <div

        >
          SL
        </div>
      ),
      selector: (row) => row.sl,
      // sortable: true,
      center: true,
      // width: "80px",
    },
    {
      name: (
        <div

        >
          Job Title
        </div>
      ),
      selector: (row) => row.jobTitle,
      center: true,
    },
    // {
    //   name: (
    //     <div

    //     >
    //       {" "}
    //       Recruiter
    //     </div>
    //   ),
    //   selector: (row) => row.recName,
    //   center: true,
    // },
    {
      name: (
        <div

        >
          Account Manager
        </div>
      ),
      selector: (row) => row.accManager,
      center: true,
    },
    {
      name: (
        <div

        >
          Date
        </div>
      ),
      selector: (row) => row.date,
      center: true,
    },
    {
      name: (
        <div

        >
          Time
        </div>
      ),
      selector: (row) => row.time,
      center: true,
    },
    {
      name: (
        <div

        >
          Total CV
        </div>
      ),
      selector: (row) => row.totalCV,
      center: true,
    },
    {
      name: (
        <div

        >
          Action
        </div>
      ),
      selector: (row) => row.action,
      center: true,
    },
  ];

  const CustomDataTable = ({ columns, data }) => {
    const customStyles = {
      headCells: {
        style: {
          paddingLeft: '8px', // Adjust left padding
          paddingRight: '8px', // Adjust right padding
        },
      },
      cells: {
        style: {
          paddingLeft: '8px', // Adjust left padding
          paddingRight: '8px', // Adjust right padding
        },
      },
    };
    // return <DataTable columns={columns} data={data} customStyles={customStyles} />;
  }

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);



  const fetchAllJobsList = async () => {
    let res = await HttpClient.requestData('jobList', 'GET');
    console.log("jobsData", res?.data);
    setJobsData(res?.data)

    if (res && res?.status) {
      let arr = res?.data?.reverse()?.map((item, index) => {
        return {
          sl: index + 1,
          id: item?._id,
          jobTitle: item?.jobTitle,
          keySkills: item?.keySkills,
          jobLocation: item?.jobLocation,
          totalResumePosted: item?.ResumeData?.length,
          recName: item?.assignedRecruiterName,
          accManager: item?.jobCreatorName,
          // accManager: item?.assignedAmName?.join(', '),
          date: moment(item.createdAt).format('DD-MM-YYYY'),
          time: moment(item.createdAt).format('HH:MM'),
          totalCV: item?.ResumeData ? item?.ResumeData?.length : 0,
          action: (
            <>
              <div className="right">
                {/* <button onClick={() => handleViews(item)}>Upload CVs</button> */}
                <button class="btn btn-outline-success eyeicn"
                  title="View CV"
                  onClick={() => {
                    handleView(item?._id);
                  }}
                  style={{
                    marginLeft: "30px",
                    fontSize: '18px',
                  }}>
                  <i class="fa-solid fa-eye"></i>
                </button>
              </div>
            </>
          )
        };
      });
      setJobsListData(arr);
      setFilteredJobsList(arr);
    }
    setLoading(false);
  };


  const handleView = (id) => {
    console.log("mnbjhgyugt7657", id)
    navigate("/hr-manager-view-cv", { state: id });
  }

  const handleViews = id => {
    console.log("321mnbjhk678", id)
    navigate('/am-view-job-upload-CV', { state: id });
    // getSingleJobDetails()
  };

  useEffect(() => {
    fetchAllJobsList()
  }, [])

  const handleAMSearch = () => {
    let filterByAM = jobsListData
    const searchTextLowerCase = searchText.toLowerCase();
    // const amName = filterByAM?.filter((item) => {
    //   return item?.accManager === searchTextLowerCase
    // });

    const amNameData = filterByAM?.filter(item => {
      if (item?.accountManager) {
        const newData = item?.accountManager?.filter(ele => ele?.trim().toLowerCase().includes(searchTextLowerCase))
        return newData?.length !== 0
      } else {
        return false;
      }
    })
    console.log("kvnjciudh76yufg", amNameData)
    // setFilteredJobsList(amNameData);
    // setJobsListData(amNameData);

    let filterByDate = jobsListData
    const filDataByDate = filterByDate?.filter((ele) => {
      const jobDate = ele?.date;
      console.log("fdgfgs5eterg", dateRange[0].endDate);
      return true;
    })
  };


  // >>>Search by date<<<
  const handleDateSearch = () => {
    // let newFilData = []
    if (singleJob?.length > 0) {
      const newFilteredJobs = singleJob.filter((job) => {
        const jobDate = moment(job?.date, 'DD-MM-YYYY');

        if (dateRange[0]) {
          const startDate = moment(dateRange[0].startDate);
          const endDate = moment(dateRange[0].endDate);
          return (
            jobDate.isSameOrAfter(startDate, 'day') &&
            jobDate.isSameOrBefore(endDate, 'day')
          );
        }
      });
      return setFilteredJobsList(newFilteredJobs)
    }

    const filteredJobs = jobsListData.filter((job) => {
      const jobDate = moment(job?.date, 'DD-MM-YYYY');

      if (dateRange[0]) {
        const startDate = moment(dateRange[0].startDate);
        const endDate = moment(dateRange[0].endDate);
        return (
          jobDate.isSameOrAfter(startDate, 'day') &&
          jobDate.isSameOrBefore(endDate, 'day')
        );
      }
      // return true;
    });
    console.log("Filtered", filteredJobs);
    setFilteredJobsList(filteredJobs);
  };

  const handleClearFilter = () => {
    setApiEnd({})
    setAccManager("")
    setRecData('')
    setSelectedDate()
    setFilteredJobsList(jobsListData)
    setSearchText("")
    setDateRange([
      { startDate: new Date(), endDate: new Date(), key: 'selection' } // Reset date range to default
    ]);
  }

  const handleInputClick = () => {
    setIsShowPicker(prev => !prev);
    setSelectedDate(cvData[0]?.createdOn);
  };


  const JobWiseResumeChecking = async (id) => {
    setAccManager(id)
    console.log("sjkdfbyu", id)
    if (id === "") {
      fetchAllJobsList();
    }

    const res = await HttpClient.requestData('job-added-by/' + id, 'GET');
    if (res && res?.data) {
      const arr = res?.data?.reverse()?.map((ele, ind) => {
        console.log("54fdsd656fdc", ele, ele?.ResumeData?.length)
        return {
          sl: ind + 1,
          jobTitle: ele?.jobTitle,
          keySkills: ele?.keySkills,
          jobLocation: ele?.jobLocation,
          totalResumePosted: ele?.ResumeData?.length,
          recName: ele?.assignedRecruiterName,
          accManager: ele?.assignedAmName?.join(', '),
          // accManager: ele?.jobCreatorName,
          date: moment(ele.createdAt).format('DD-MM-YYYY'),
          time: moment(ele.createdAt).format('HH:MM'),
          totalCV: ele?.ResumeData?.length,
          action: (
            <>
              <div className="right">
                {/* <button onClick={() => handleViews(item)}>Upload CVs</button> */}
                <button class="btn btn-outline-success eyeicn"
                  title="View CV"
                  onClick={() => {
                    handleView(ele?._id);
                  }}
                  style={{
                    marginLeft: "30px",
                    fontSize: '18px',
                  }}>
                  <i class="fa-solid fa-eye"></i>
                </button>
              </div>
            </>
          )
        }
      })
      console.log("fjnjih78y4", arr)
      setFilteredJobsList(arr)
      setSingleJOb(arr);

    } else {
      setFilteredJobsList([])
    }
  }


  // >>>fetch all account manager<<<
  const fetchAllAccountManagerList = async () => {
    let res = await HttpClient.requestData("profileAdded", "GET");
    console.log("ghdfdjhui", res?.accountManegerData)
    if (res && res?.status) {
      let managerData = [];
      res?.accountManegerData?.forEach((element) => {
        if (element?.userType === "AccountManager") {
          managerData?.push(element);
        }
      });
      setAccountManagerData(managerData);
    }
  };

  const RecruiterWiseResumeChecking = (id) => {
    // recruiterName
    setRecData(id)
    if (id == "") {
      fetchAllJobsList();
    }
    let filterByAM = jobsListData
    const recruiterNameData = filterByAM?.filter(item => {
      if (item?.recruiterName) {
        const newData = item?.recruiterName?.filter(ele => ele?.includes(id))
        return newData?.length !== 0
      } else {
        return false;
      }
    })
    console.log("nsbvju78876", id, jobsListData, recruiterNameData)
    setFilteredJobsList(recruiterNameData)
  }



  // const dateRangeRef = useRef(null);
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //   console.log("m24145nbmnnjk",event)
  //     if (dateRangeRef?.current && !dateRangeRef?.current?.contains(event?.target)) {
  //       setIsShowPicker(false);
  //     }
  //   };

  //   document.addEventListener("click", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);


  const prepareDataForCSV = () => {
    if (filteredJobsList.length > 0) {
      return filteredJobsList.map((row) => ({
        Name: row?.jobTitle,
        Account_manager: row?.accManager,
        Skills: row?.keySkills,
        Location: row.jobLocation,
        Date: row?.date,
        Time: row?.time,
        TotalCV: row?.totalCV
      }));
    } else {
      return jobsListData.map((row) => ({
        Name: row?.jobTitle,
        Account_manager: row?.accManager,
        Skills: row?.keySkills,
        Location: row?.jobLocation,
        Date: row?.date,
        Time: row?.time,
        TotalCV: row?.totalCV
      }));
    }
  };


  {/**job filter start**/ }

  const [isOpen, setIsopen] = useState(false);

  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  }

  {/**job filter end**/ }

  // >>>>Handle Filter Input value Change<<<<
  const HandleChange = (e) => {
    setJobDetails({
      ...jobDetail,
      [e.target.name]: e.target.value,
    });
  };

  // >>>>Handle API end<<<<
  const handleApiEnd = (filterValue, filterKey) => {
    console.log("gh4fg6", filterValue, filterKey)


    setApiEnd(prev => ({
      ...prev,
      [filterKey]: {
        key: filterKey,
        value: filterValue
      }
    }))
  }

  // >>>>Handle filter submit<<<<
  const handleFilter = async () => {
    console.log("fg74fx1g3", jobDetail)
    const endArr = Object.values(apiEnd)
    console.log("rg54r1f", endArr)
    const queryParams = endArr.map(({ key, value }) => `${key}=${encodeURIComponent(value)}`).join('&');
    const res = await HttpClient.requestData("jobFilter?" + queryParams, "GET");
    if (res && res?.status) {
      let arr = res?.data?.map((item, index) => {
        return {
          sl: index + 1,
          id: item?._id,
          jobTitle: item?.jobTitle,
          keySkills: item?.keySkills,
          jobLocation: item?.jobLocation,
          totalResumePosted: item?.ResumeData?.length,
          recName: item?.assignedRecruiterName,
          accManager: item?.jobCreatorName,
          // accManager: item?.assignedAmName?.join(', '),
          date: moment(item.createdAt).format('DD-MM-YYYY'),
          time: moment(item.createdAt).format('HH:MM'),
          totalCV: item?.ResumeData ? item?.ResumeData?.length : 0,
          action: (
            <>
              <div className="right">
                {/* <button onClick={() => handleViews(item)}>Upload CVs</button> */}
                <button class="btn btn-outline-success eyeicn"
                  title="View CV"
                  onClick={() => {
                    handleView(item?._id);
                  }}
                  style={{
                    marginLeft: "30px",
                    fontSize: '18px',
                  }}>
                  <i class="fa-solid fa-eye"></i>
                </button>
              </div>
            </>
          )
        }
      })
      setFilteredJobsList(arr)
      setJobDetails(INITIAl)
      setApiEnd({});
      setIsopen(false)
    } else {
      toast.error(res?.message)
      setJobDetails(INITIAl)
      setApiEnd({});
      setIsopen(false)
    }
  }

  useEffect(() => {
    fetchAllAccountManagerList()
  }, [])

  return (
    <>
      <div onClick={() => setIsShowPicker(false)}>

        <div
          className=""
          style={{ width: '100%', alignItems: 'center', justifyContent: 'center', margin: '15px 0 20px' }}
        >
          <h2 style={{ fontSize: '26px', fontWeight: '600', textAlign: 'center' }}>Jobs List</h2>
        </div>
        <div className="filterDiv">
          <div>

          </div>
          <div style={{ width: "25%" }}>
            <select
              style={{ width: "100%", height: "44px" }}
              onChange={(e) => { JobWiseResumeChecking(e.target.value) }}
              value={accManager}
            >
              <option value="">Search by Account Manager...</option>
              {
                AcoountManagerData?.map((ele, index) => {
                  // console.log("bcvwye635",ele)
                  return (
                    <option value={ele?._id} key={index}>{ele?.fullName}</option>
                  )
                })
              }
            </select>
          </div>

          <div className="calenderpacakgediv" style={{ width: "25%" }}>
            <button
              style={{
                backgroundColor: "white",
                color: "black",
                border: "1px solid #999",
                // margin: "19px",
                width: "15rem"
              }}
              onClick={(e) => {
                e.stopPropagation()
                handleInputClick();
              }}
              placeholder="Select date..."
              readOnly
            > {selectedDate ? moment(selectedDate?.[0]?.startDate).format('DD-MM-YYYY') : "Start date"} - {selectedDate ? moment(selectedDate?.[0]?.endDate).format('DD-MM-YYYY') : "End date"}
            </button>
          </div>
          <div className="d-flex" style={{ width: "30%" }}>
            <button
              className="btn btn-outline-success"
              onClick={() => { handleDateSearch(); setIsShowPicker(false); }}
              style={{ fontSize: '11px', width: '100px', height: '30px', padding: '5px 2px', }}
            >
              Search
            </button>
            <button
              className="btn btn-outline-success mx-2"
              onClick={() => {
                handleClearFilter();
                setIsShowPicker(false)
              }}
              style={{ fontSize: '11px', width: '100px', height: '30px', padding: '5px 2px', whiteSpace: "nowrap" }}
            >
              Clear filter
            </button>

            <button
              onClick={ToggleSidebar}
              className="btn btn-outline-success mx-2"
              style={{ fontSize: '11px', width: '100px', height: '30px', padding: '5px 2px', whiteSpace: "nowrap" }}
            >
              Advance filter
            </button>

            <CSVLink
              className="btn btn-outline-success"
              style={{
                fontSize: "20px",
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              data={prepareDataForCSV()}
              filename={"report.csv"}
              title="Download file"
            >
              <i class="fa-regular fa-circle-down"></i>
            </CSVLink>


          </div>
          <div style={{ width: "20%" }}></div>
        </div>

        <div className="datepickerdivcalender" style={{ left: '41%' }} onClick={(e) => e.stopPropagation()}>
          {isShowPicker && (
            <DateRangePicker
              onChange={(ranges) => {
                setDateRange([ranges.selection]);
                setSelectedDate([ranges.selection])
                setIsShowPicker(false)
              }}
              showDateDisplay={false}
              moveRangeOnFirstSelection={false}
              ranges={dateRange}
            />
          )}
        </div>


        {
          loading ? (
            <Loader />
          ) : (
            <div className="card-body">
              <DataTable
                columns={columns}
                data={filteredJobsList}
                // customStyles={CustomDataTable}
                // data={jobsListData}
                pagination
              />
            </div>
          )
        }
      </div>


      {/* Filter Modal */}
      <section className={`filterdropbox ${isOpen == true ? 'active' : ''}`}>
        <div className="filterdrop_body">
          <div className="btn btn-primary closebtn" onClick={ToggleSidebar}><i className="fa fa-times"></i></div>
          <h2 className="headline">Filter</h2>

          <div className="row">
            <div className="form-group col-12">
              <label for="exampleInputEmail1">
                Designation<span style={{ color: 'red' }}>*</span> :
              </label>
              <input
                type="text"
                name="designation"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={jobDetail?.designation}
                onChange={(e) => {
                  HandleChange(e)
                  handleApiEnd(e.target.value, "designation")
                }
                }
                placeholder="Enter designation..."
              />
            </div>

            <div className="form-group col-12">
              <label for="exampleInputEmail1">
                Employment Type<span style={{ color: 'red' }}>*</span> :
              </label>
              <select
                className="form-select"
                aria-label="Disabled select example"
                onChange={e => {
                  setJobDetails({
                    ...jobDetail,
                    employmentType: e.target.value,
                  });
                  handleApiEnd(e.target.value, "employmentType")
                }}
                value={jobDetail?.employmentType}
              >
                <option value="">Select Employment Type...</option>
                <option value="W2 employee">W2 employee</option>
                <option value="C2C">C2C</option>
                <option value="1099">1099</option>
                <option value="FullTime">FullTime</option>
                <option value="PartTime">PartTime</option>
              </select>
            </div>

            <div className="form-group  col-12">
              <label for="exampleInputEmail1">
                Skills<span style={{ color: 'red' }}>*</span> :
              </label>
              <input
                type="text"
                name="keySkills"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={jobDetail?.keySkills}
                onChange={(e) => {
                  HandleChange(e)
                  handleApiEnd(e.target.value, "keySkills")
                }
                }
                placeholder="Enter key skills"
              />
            </div>

            <div className="form-group col-12">
              <label for="exampleInputEmail1">
                Client<span style={{ color: 'red' }}>*</span> :
              </label>
              <input
                type="text"
                name="department"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={jobDetail?.department}
                onChange={(e) => {
                  HandleChange(e)
                  handleApiEnd(e.target.value, "department")
                }
                }
                placeholder="Enter full client"
              />
            </div>

            <div className="form-group col-12">
              <label
                for="exampleInputEmail1"
                style={{
                  display: 'block',
                  marginBottom: '8px',
                  marginTop: '35px',
                }}
              >
                Work Mode<span style={{ color: 'red' }}>*</span> :
              </label>
              <select
                className="form-select"
                aria-label="Disabled select example"
                onChange={e => {
                  setJobDetails({
                    ...jobDetail,
                    workMode: e.target.value,
                  });
                  handleApiEnd(e.target.value, "workMode")
                }}
                value={jobDetail?.workMode}
              >
                <option value="">Select Work Mode...</option>
                <option value="WFH">WFH</option>
                <option value="On-Site">On-Site</option>
                <option value="Hybrid">Hybrid</option>
              </select>
            </div>

            <div className="form-group  col-12">
              <label for="exampleInputEmail1">
                Location{' '}
                <span style={{ color: 'red' }}>*</span> :
              </label>
              <input
                type="text"
                name="jobLocation"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={jobDetail?.jobLocation}
                onChange={e => {
                  setJobDetails({
                    ...jobDetail,
                    jobLocation: e.target.value,
                  });
                  handleApiEnd(e.target.value, "jobLocation")
                }}
                placeholder="Enter full experience"
              />
            </div>

            <div className="form-group  col-12">
              <label for="exampleInputEmail1">
                Experience{' '}
                <span style={{ color: 'red' }}>*</span> :
              </label>
              <input
                type="text"
                name="experience"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={jobDetail?.experience}
                onChange={e => {
                  setJobDetails({
                    ...jobDetail,
                    experience: e.target.value,
                  });
                  handleApiEnd(e.target.value, "experience")
                }}
                placeholder="Enter full experience"
              />
            </div>

            <div className="form-group  col-12">
              <label for="exampleInputEmail1">
                Salary / Rate{' '}
                <span
                  style={{
                    fontSize: '80%',
                    fontWeight: 'normal',
                  }}
                >
                  ( per Annum )
                </span>
                <span style={{ color: 'red' }}>*</span> :
              </label>
              <input
                type="text"
                name="salary"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={jobDetail?.salary}
                onChange={e => {
                  setJobDetails({
                    ...jobDetail,
                    salary: e.target.value,
                  });
                  handleApiEnd(e.target.value, "salary")
                }}
                placeholder="Enter salary/rate..."
              />
            </div>

            <div className="form-group col-12">
              <label for="exampleInputEmail1">Industry Type :</label>
              <input
                type="text"
                name="industryType"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={jobDetail?.industryType}
                onChange={(e) => {
                  HandleChange(e)
                  handleApiEnd(e.target.value, "industryType")
                }}
                placeholder="Enter full industry type"
              />
            </div>

            <div className="form-group  col-12">
              <label for="exampleInputEmail1">Education :</label>
              <input
                type="text"
                name="education"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={jobDetail?.education}
                onChange={(e) => {
                  HandleChange(e)
                  handleApiEnd(e.target.value, "education")
                }}
                placeholder="Enter education"
              />
            </div>

          </div>
        </div>
        <div className="d-flex justify-content-center">
          <button className="btn btn-success w-50"
            onClick={handleFilter}
          >
            Search
          </button>
        </div>
      </section>

      {/* Filter Modal */}

    </>
  )
}

export default ViewAllJobReport