import React, { useEffect, useState } from 'react';
import HttpClient from '../../components/HttpClient';
import { useNavigate } from 'react-router-dom';
import { Link, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import './Viewjobs.css';
import img1 from '../../../src/workimageUploadschhola.jpg';
const initValue = {
  image: [],
};

const ViewJobs = () => {
  const [singleData, setSingleData] = useState({});
  const [formValue, setFormValue] = useState(initValue);
  const [loading, setLoading] = useState(false);
  const [imageLoader, setImgLoader] = useState(false);
  const [numberOfCVUpload, setNumberofCVUpload] = useState(null);
  const [jobId, setJobId] = useState("")
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate()

  console.log('singleData', singleData);

  const ViewCV = () => {
    numberOfCVUpload > 0
      ? navigate('/hr-manager-view-jobs-list', { state: jobId })
      : toast.error('No cv uploaded by recruiter ');
  }

  const getSingleJobDetails = async id => {
    setLoading(true);
    const res = await HttpClient.requestData(`job/${id}`, 'GET');

    if (res && res?.status) {
      setSingleData(res?.data[0]);
      setNumberofCVUpload(res?.data[0]?.ResumesData[0]?.resume.length);
      // console.log('CVLENGTH', res?.data[0]?.resumes[0]?.resume.length);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("nxmbcvsdkghfuwey5646", location?.state)
    // return
    if (location?.pathname == '/hr-manager-view-cv') {
      getSingleJobDetails(location?.state);
      setJobId(location?.state);
    }
  }, [params?.id]);

  console.log(singleData, 'singleDatatata');
  return (
    <div className="Main">
      <div
        className=""
        style={{ width: '100%', alignItems: 'center', justifyContent: 'center', margin: '15px 0 20px' }}
      >
        <h2 style={{ fontSize: '26px', fontWeight: '600', textAlign: 'center' }}>Job Details</h2>
      </div>

      <div className="leftsection">
        <div className="topleft">
          <h5>
            <span className="mr-2">Job Title :</span> {singleData?.jobTitle}
          </h5>
          {singleData?.designation && (
            <h5>
              <span className="mr-2">Designation :</span> {singleData?.designation}
            </h5>
          )}
          <h5>
            <span className="mr-2">Employment Type :</span> {singleData?.employmentType}
          </h5>
          <h5>
            <span className="mr-2">Client :</span> {singleData?.department}
          </h5>
          <h5>
            <span className="mr-2">Key Skills :</span> {singleData?.keySkills?.join(',')}
          </h5>
          {singleData?.roleCategory && (
            <h5>
              <span className="mr-2">Role Category :</span> {singleData?.roleCategory}
            </h5>
          )}
          <h5>
            <span className="mr-2">Role :</span> {singleData?.role}
          </h5>
        </div>
        <div className="topright">
          <h5>
            <span className="mr-2">Work Mode :</span> {singleData?.workMode}
          </h5>
          <h5>
            <span className="mr-2">Job Location :</span> {singleData?.jobLocation?.join(',')}
          </h5>
          <h5>
            <span className="mr-2">
              Experience{' '}
              <span
                style={{
                  fontSize: '80%',
                  fontWeight: 'normal',
                }}
              >
                ( in Year )
              </span>
              :
            </span>
            {singleData?.experience}
          </h5>
          {/* <h5>
            <span>rating:</span> {singleData?.rating}
          </h5> */}
          <h5>
            <span className="mr-2">
              Salary / Rate
              <span
                style={{
                  fontSize: '80%',
                  fontWeight: 'normal',
                }}
              >
                ( per Annum )
              </span>
              :
            </span>{' '}
            {singleData?.salary}
          </h5>
        </div>
      </div>
      <div className="rightsection">
        <div className="left_leftsde">
          {singleData?.openings && (
            <h5>
              <span>
                Openings{' '}
                <span
                  style={{
                    fontSize: '80%',
                    fontWeight: 'normal',
                  }}
                >
                  ( in Nos)
                </span>
                :
              </span>{' '}
              {singleData?.openings}
            </h5>
          )}
          {/* <h5>
            <span>ApplicantNumber:</span> {singleData?.applicantNumber}
          </h5> */}
          <h5>
            <span>Description :</span> {singleData?.description}
          </h5>
          {singleData?.industryType && (
            <h5>
              <span>Industry Type :</span> {singleData?.industryType}
            </h5>
          )}
          {singleData?.education?.length > 0 && (
            <h5>
              <span>Education :</span> {`${singleData?.education}`}
            </h5>
          )}
          <h5>
            <span>Job Creater Type :</span> {singleData?.jobCreaterType}
          </h5>
        </div>

        <div className="right_leftsde">
          <div className="cvdetails">
            <button className="btn">
              {numberOfCVUpload > 0 ?
                <span onClick={ViewCV}>View All CVs</span>
                :
                <span>No CVs Found</span>
              }
            </button>
            {numberOfCVUpload > 0 ? (
              <div className="cvimage" style={{ marginTop: '12px' }}>
                {Array.from({ length: numberOfCVUpload }, (_, index) => index + 1)
                  .slice(0, 3)
                  ?.map((ele, inx) => {
                    console.log('inx', inx);
                    return (
                      <div className="cvimgown">
                        <img
                          id="imageone"
                          src="https://t4.ftcdn.net/jpg/01/39/64/23/240_F_139642321_ptZco8pbwESSjMnsxjx6KXg4DkoCmHES.jpg"
                        />
                      </div>
                    );
                  })}

                {singleData?.ResumesData.slice(0, 3)?.map((ele, inx) => {
                  return (
                    <div className="cvimgown">
                      <img
                        id="imageone"
                        src="https://t4.ftcdn.net/jpg/01/39/64/23/240_F_139642321_ptZco8pbwESSjMnsxjx6KXg4DkoCmHES.jpg"
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <p></p>
            )}

            {/* <div className="cvimgown">
                <img
                  // style={{ marginLeft: '20' }}
                  id="imageone"
                  src="https://t4.ftcdn.net/jpg/01/39/64/23/240_F_139642321_ptZco8pbwESSjMnsxjx6KXg4DkoCmHES.jpg"
                />
              </div> */}


          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewJobs;
