import React, { useEffect, useState } from 'react';
// import { GoPrimitiveDot } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
import { Button } from '../components';
import { earningData } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';
import { MdOutlineSupervisorAccount } from 'react-icons/md';
import { HiOutlineRefresh } from 'react-icons/hi';
import { BsBoxSeam } from 'react-icons/bs';
import HttpClient from '../components/HttpClient';
import toast from 'react-hot-toast';
// import product9 from "../data/product9.jpg";

const Ecommerce = () => {
  const { currentColor, userProfileData, recruiterAssignJob } = useStateContext();
  // console.log('userProfileDatagdfgdfgdfgdfgdf', recruiterAssignJob);
  const [allData, setAllData] = useState({});
  const [AMjob, setAMjob] = useState(null);
  const [Recruiterjob, setRecruiterjob] = useState(null);
  const navigate = useNavigate();

  // console.log('AMjob', AMjob);

  const allHRData = async () => {
    let res = await HttpClient.requestData('hrAlldata', 'GET');
    if (res && res?.status) {
      setAllData(res?.data);
    }
  };

  const allAMData = async id => {
    let res = await HttpClient.requestData(`job-added-by/${id}`, 'GET');
    if (res && res?.status) {
      setAMjob(res?.data?.length);
    }
  };
  // const allRecruiterData = async id => {
  //   let res = await HttpClient.requestData('jobList', 'GET');
  //   if (res && res?.status) {
  //     setRecruiterjob(res?.data?.length);
  //   } else {
  //     toast.error(res?.message);
  //   }
  // };

  const fetchAllJobsList = async id => {
    let res = await HttpClient.requestData('jobList', 'GET');
    let newArr = [];
    res?.data?.forEach(ele => {
      ele?.assignedRecruiter?.map(element => {
        if (element === id) {
          newArr.push(ele);
        }
      });
    });
    newArr && setRecruiterjob(newArr?.length);
  };

  useEffect(() => {
    userProfileData?.userType === 'HR' && allHRData();
    userProfileData?.userType === 'AccountManager' && allAMData(userProfileData?._id);
    userProfileData?.userType === 'Recruiter' && fetchAllJobsList(userProfileData?._id);
  }, [userProfileData]);

  return (
    <div className="pt-4 bg-sky-100 h-screen rounded">
      {userProfileData?.userType === 'HR' && (
        <div className="flex flex-wrap lg:flex-nowrap justify-center">
          <div
            className="flex m-3 flex-wrap justify-center gap-1 items-center"
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('/add-account-manager')}
          >
            <div
              key="Recruiter"
              className="bg-white h-[18rem] dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl "
              style={{ textAlign: 'center' }}
            >
              <button
                type="button"
                style={{ color: '#03C9D7', backgroundColor: '#E5FAFB' }}
                className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
              >
                <MdOutlineSupervisorAccount />
              </button>
              <p className="mt-3">
                <span className="text-lg font-semibold">{allData?.totalAddedAccountManager}</span>
                {/* <span className={`text-sm text-red-600 ml-2`}>50%</span> */}
              </p>
              <p className="text-sm text-black  mt-1 font-bold">Account Manager</p>
            </div>
          </div>
          <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
            <div
              key="Recruiter"
              className="bg-white h-[18rem] dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl "
              style={{ textAlign: 'center' }}
            >
              <button
                type="button"
                style={{ color: 'rgb(255, 244, 229)', backgroundColor: 'rgb(254, 201, 15)' }}
                className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
              >
                <BsBoxSeam />
              </button>
              <p className="mt-3">
                <span className="text-lg font-semibold">{allData?.jobCountByAM}</span>
                <span className={`text-sm text-red-600 ml-2`}>{/* {item.percentage} */}</span>
              </p>
              <p className="text-sm text-black  mt-1 font-bold">Job added by Account Manager</p>
            </div>
          </div>
          <div
            className="flex m-3 flex-wrap justify-center gap-1 items-center"
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('/add-and-manage-job')}
          >
            <div
              key="Recruiter"
              className="bg-white h-[18rem] dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl "
              style={{ textAlign: 'center' }}
            >
              <button
                type="button"
                style={{ color: 'rgb(0, 194, 146)', backgroundColor: 'rgb(235, 250, 242)' }}
                className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
              >
                <HiOutlineRefresh />
              </button>
              <p className="mt-3">
                <span className="text-lg font-semibold">{allData?.jobCountByHR}</span>
                <span className={`text-sm text-red-600 ml-2`}>{/* {item.percentage} */}</span>
              </p>
              <p className="text-sm text-black  mt-1 font-bold">Job added by HR</p>
            </div>
          </div>
        </div>
      )}

      {userProfileData?.userType === 'AccountManager' && (
        <div
          className="flex flex-wrap lg:flex-nowrap justify-center"
          style={{ cursor: 'pointer' }}
          onClick={() => navigate('/add-and-manage-manager-job')}
        >
          <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
            <div
              key="Recruiter"
              className="bg-white h-[18rem] dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl "
              style={{ textAlign: 'center' }}
            >
              <button
                type="button"
                style={{ color: 'rgb(0, 194, 146)', backgroundColor: 'rgb(235, 250, 242)' }}
                className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
              >
                <HiOutlineRefresh />
              </button>
              <p className="mt-3">
                <span className="text-lg font-semibold">{AMjob}</span>
                <span className={`text-sm text-red-600 ml-2`}>{/* {item.percentage} */}</span>
              </p>
              <p className="text-sm text-black  mt-1 font-bold">Jobs added by Account Manager</p>
            </div>
          </div>
        </div>
      )}
      {userProfileData?.userType === 'Recruiter' && (
        <div className="flex flex-wrap lg:flex-nowrap justify-center">
          <div
            className="flex m-3 flex-wrap justify-center gap-1 items-center"
            onClick={() => {
              navigate('/jobs-list');
            }}
          >
            <div
              key="Recruiter"
              className="bg-white h-[18rem] dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl "
              style={{ textAlign: 'center' }}
            >
              <button
                type="button"
                style={{ color: 'rgb(0, 194, 146)', backgroundColor: 'rgb(235, 250, 242)' }}
                className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
              >
                <HiOutlineRefresh />
              </button>
              <p className="mt-3">
                {Recruiterjob && <span className="text-lg font-semibold">{Recruiterjob}</span>}
                <span className={`text-sm text-red-600 ml-2`}>{/* {item.percentage} */}</span>
              </p>
              <p className="text-sm text-black  mt-1 font-bold">Total Jobs</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Ecommerce;
