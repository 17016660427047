import React, { useEffect, useState } from 'react'
// import '../RecruiterJobs/JobList.css'
import HttpClient from '../../../components/HttpClient';
import { Link, useNavigate } from 'react-router-dom';
import { useStateContext } from '../../../contexts/ContextProvider';
import DataTable from 'react-data-table-component';


const Loader = () => {
  return (
    <div style={{ position: 'fixed', top: '25%', left: '40%', zIndex: 9999, fontSize: "25px", marginLeft: "20px" }}>
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};
const ViewAllManagerJob = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [jobsListData, setJobsListData] = useState([]);
  const [jobsData, setJobsData] = useState([]);

  const { userProfileData } = useStateContext();
  console.log('jobsListData', jobsListData, userProfileData);

  const columns = [
    {
      name: (
        <div
        >
          SL
        </div>
      ),
      selector: (row) => row.sl,
      sortable: true,
      center: true,
    },
    {
      name: (
        <div
        >
          Job Title
        </div>
      ),
      selector: (row) => row.jobTitle,
      center: true,
    },
    {
      name: (
        <div
        >
          Location
        </div>
      ),
      selector: (row) => row.jobLocation,
      center: true,
    },
    // {
    //   name: (
    //     <div 
    //     >
    //       CV Uploaded
    //     </div>
    //   ),
    //   selector: (row) => row.totalResumePosted,
    //   center: true,
    // },
    // {
    //   name: (
    //     <div

    //     >
    //       Time
    //     </div>
    //   ),
    //   selector: (row) => row.time,
    //   center: true,
    // },
    // {
    //   name: (
    //     <div

    //     >
    //       Total CV
    //     </div>
    //   ),
    //   selector: (row) => row.totalCV,
    //   center: true,
    // },
    {
      name: (
        <div
        >
          Action
        </div>
      ),
      selector: (row) => row.action,
      center: true,
    },
  ];


  const fetchAllJobsList = async (id) => {
    let res = await HttpClient.requestData(`job-added-by/${id}`, 'GET');
    // console.log('jobsDatghfghgfa', res);
    setJobsData(res);
    if (res && res?.status) {
      let arr = res?.data?.reverse()?.map((item, index) => {
        return {
          sl: index + 1,
          id: item?._id,
          jobTitle: item?.jobTitle,
          keySkills: item?.keySkills,
          jobLocation: item?.jobLocation,
          recruiterName: item?.assignedRecruiterName,
          dataandtime: item?.createdAt,
          action: (
            <>
              <div className='right'>
                <button class="btn btn-outline-success eyeicn"
                  title="View CV"
                  // onClick={() => {
                  //   setModal(true); 
                  //   viewCVdata(item)
                  // }}
                  onClick={() => {
                    handleView(item?._id);
                    // setModal(true);
                  }}
                  style={{
                    // fontWeight: 'bold',
                    marginLeft: "30px",
                    fontSize: '18px',
                  }}><i class="fa-solid fa-eye"></i>
                </button>
                <button className='mx-3'
                  onClick={() => handleUploadCV(item?._id)}
                >Upload CVs</button>
              </div>
            </>
          )
        };
      });
      // console.log("bvr56tyjk",arr)
      setJobsListData(arr);
    }
    setLoading(false);

  };

  const handleView = id => {
    console.log("nsbdhfu89", id)
    navigate('/hr-manager-view-cv', { state: id });
  };

  const handleUploadCV = id => {
    console.log("nsbdhfu89", id)
    navigate('/am-job-upload-CV', { state: id });
  };

  // handleCVuploads

  useEffect(() => {
    if (userProfileData?._id) {
      fetchAllJobsList(userProfileData?._id);
    }
  }, [userProfileData?._id]);
  return (
    <>
      {/* <DataTable columns={columns} data={jobsListData} pagination /> */}

      {loading ? ( // Render loader if data is loading
        <Loader />
      ) : jobsListData && jobsListData?.length === 0 ? (
        <p>No data available</p>
      ) : (
        <>

          {/* <div className='list'>
          </div> */}
          <div
            className=""
            style={{
              width: '100%',
              padding: '20px 20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
            }}
          >
            <div className="list">
              <DataTable columns={columns}
                data={jobsListData}
                pagination />
            </div>
          </div>
          {/* {jobsListData?.map((item, index) => {
              // console.log('Itemgdfgd', item);
              return (
                <ul className="parentlist" key={index}>
                  <div className="list">
                    <div className="left mb-2">
                      <h3 style={{ fontSize: '15px', fontWeight: '600', color: '#000' }}>
                        JobTitle:{' '}
                        <span style={{ marginLeft: '6px', fontWeight: '500', color: 'rgb(92 89 89)' }}>
                          {item?.jobTitle}
                        </span>
                      </h3>
                      <h4 style={{ fontSize: '13px', fontWeight: '600', color: '#000' }}>
                        KeySkill:
                        <span style={{ marginLeft: '6px', fontWeight: '400', color: 'rgb(92 89 89)' }}>
                          {item?.keySkills?.join(',')}
                        </span>
                      </h4>
                      <h4 style={{ fontSize: '12px', fontWeight: '600', color: '#000' }}>
                        Location:
                        <span style={{ marginLeft: '6px', fontWeight: '400', color: 'rgb(92 89 89)' }}>
                          {item?.jobLocation?.join(',')}
                        </span>
                      </h4>

                      {item?.recruiterName && (
                        <h4 style={{ fontSize: '12px', fontWeight: '600', color: '#000' }}>
                          Recruiter :
                          <span style={{ marginLeft: '6px', fontWeight: '400', color: 'rgb(92 89 89)' }}>
                            {item?.recruiterName?.join(',')}
                          </span>
                        </h4>
                      )}

                      {item?.dataandtime && (
                        <h4 style={{ fontSize: '12px', fontWeight: '600', color: '#000' }}>
                          Date & time :
                          <span style={{ marginLeft: '6px', fontWeight: '400', color: 'rgb(92 89 89)' }}>
                            {new Date(item?.dataandtime).getUTCFullYear()}-{new Date(item?.dataandtime).getUTCMonth() + 1}
                            -{new Date(item?.dataandtime).getUTCDate()} & {new Date(item?.dataandtime).getUTCHours()} :{' '}
                            {new Date(item?.dataandtime).getUTCMinutes()}
                          </span>
                        </h4>
                      )}
                    </div>
                    <div className="right">
                      <button style={{ marginright: "5px", height: '40px' }} onClick={() => handleView(item?.id)}>View Jobs And View CVs</button>
                      <button style={{ marginLeft: "2px", height: '40px' }} onClick={() => handleCVuploads(item?.id)}>Upload CVs</button>

                    </div>
                  </div>
                </ul>
              );
            })} */}
        </>

      )}
    </>
  );
};


export default ViewAllManagerJob