import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import DataTable from 'react-data-table-component';
import HttpClient from '../../components/HttpClient';
import Swal from 'sweetalert2'
const Loader = () => {
    return (
        <div style={{ position: 'fixed', top: '25%', left: '40%', zIndex: 9999, fontSize: "25px", marginLeft: "20px" }}>
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
};

const AddAndManageStatus = () => {
    const [loading, setLoading] = useState(true);
    const [addStatus, setAddStatus] = useState("")
    const [statusData, setStatusData] = useState([])
    // >>>>Data-Table-Coloum<<<<
    const columns = [
        {
            name: (
                <div
                >
                    SL
                </div>
            ),
            selector: (row) => row.sl,
            sortable: true,
            center: true,
        },
        {
            name: (
                <div
                >
                    Status
                </div>
            ),
            selector: (row) => row.status,
            center: true,
        },
        {
            name: (
                <div
                >
                    Action
                </div>
            ),
            selector: (row) => row.action,
            center: true,
        },
    ];

    // >>>>Fetch All Status List Data<<<<
    const fetchAllStatus = async () => {
        const res = await HttpClient.requestData('jobStatus', 'GET');
        // if (res && res?.status) {
            let arr = res?.data?.map((ele, ind) => {
                return {
                    sl: ind + 1,
                    status: ele?.status,
                    action: (
                        <span
                            title="Delete"
                            onClick={() => handleDelete(ele?._id)}
                        >
                            <i class="fa-solid fa-trash-can"
                                style={{
                                    cursor: "pointer",
                                    marginRight: "5px",
                                    padding: "5px",
                                    fontSize: "1.5em",
                                    color: "#C4554D",
                                    backgroundColor: "#FAECEC",
                                }}
                            ></i>
                        </span>
                    )
                }
            })
            setStatusData(arr)
        // }
        setLoading(false)
    }

    // >>>>Handle Delete Status<<<<  
    const handleDelete = async (id) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'You are about to delete this status!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            confirmButtonColor: '#d33',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        });

        if (result.isConfirmed) {
            try {
                const res = await HttpClient.requestData(`jobStatus/${id}`, 'DELETE');
                Swal.fire('Deleted!', 'Status has been deleted.', 'success');
                fetchAllStatus()
            } catch (error) {
                Swal.fire('Error!', 'Failed to delete status.', 'error');
            }
        }
    };

    // >>>>Handle Submit<<<<
    const onSubmit = async () => {
        const regex = /^[a-zA-Z]+$/;
        if (addStatus?.trim() === "") {
            return toast.error("Please fill the status!")
        } else if (!regex.test(addStatus)) {
            return toast.error("Status should contain only alphabetic characters!")
        } else {
            let data = {
                status: addStatus
            }
            const res = await HttpClient.requestData('jobStatus', 'POST', data);

            toast.success("Status added successfully!")
            console.log("7f7g413dx1", addStatus)
            setAddStatus('')
            fetchAllStatus()
            setLoading(false)

        }
    }

    useEffect(() => {
        fetchAllStatus()
    }, [])
    return (
        <>
            <div className="card-body" style={{ margin: "42px", backgroundColor: "#fff" }}>
                <h1 style={{ color: "#000", fontWeight: "500" }}>Add Status</h1>
                <div>
                    <div className="form-group col-12">
                        <label for="exampleInputEmail1">
                            Status<span style={{ color: 'red' }}>*</span> :
                        </label>
                        <input
                            type="text"
                            name="status"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            value={addStatus}
                            onChange={(e) => setAddStatus(e.target.value)}
                            placeholder="Add status..."
                        />
                    </div>
                    <div className='d-flex justify-content-center'>
                        <button className="btn btn-primary w-25" onClick={onSubmit}>
                            Submit
                        </button>
                    </div>
                </div>
            </div>

            {/* {loading ? (
                    <Loader />
                ) : statusData && statusData?.length === 0 ? (
                    <p>No Data Found...</p>
                ) : ( */}
            <>
                <div style={{
                    width: '100%',
                    padding: '0 20px 20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                }}>
                    <div className="list">
                        <DataTable columns={columns}
                            data={statusData}
                            pagination />
                    </div>
                </div>
            </>
            {/* // )
                // } */}
        </>
    )
}

export default AddAndManageStatus