import React, { useEffect, useState } from "react";
import HttpClient from "../../components/HttpClient";
import { Link, useLocation, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import ImageLoader from "../Loader/ImageLoader";
import "../HrManagerJobs/Viewjobs.css";
import { Skeleton } from "@mui/material";
import DataTable from "react-data-table-component";
import SelectableInput from "../Job/SelectableInput";
import Swal from "sweetalert2";
import { IoMdDownload } from 'react-icons/io';
import {Button ,Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
// import {modal, modalbody}

// import Select from "react-select";

const recruitData = {
  name: "",
  email: "",
  experience: "",
  phoneNo: "",
  skills: [],
  location: "",
};

const ViewJobs = () => {
  const [singleData, setSingleData] = useState({});
  const [modal, setModal] = useState(false);
  const [AcoountRecruiterData, setAcoountRecruiterData] = useState([]);
  const allowedFileTypes = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];
  // console.log("dacvsavbcgvb", AcoountRecruiterData);
  
  const [hide , setHide] = useState(true)

  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [imageLoader, setImgLoader] = useState(false);
  const [ResumeId, setResumeId] = useState("");
  const [UploadedCVdata, setUploadedCVdata] = useState([])
  const pattern = new RegExp(/^\d{1,10}$/);

  console.log("dvghab", UploadedCVdata);
  const [uploadedCV, setUploadedCV] = useState("");
  const [recruiterData, setRecruiterdata] = useState(recruitData);
  const [error, setError] = useState("");

  const [countCV, setCountCV] = useState([]);

  console.log("uploadedCV", countCV);
  const params = useParams();
  const location = useLocation();

  console.log("singleData", singleData);

  const resumeData = async (id) => {
    let data = {
      jobId: id,
    };
    let response = await HttpClient.requestData("get-resume", "POST", data);
    console.log("qwertyuio", response?.data);
    //  let arr = response?.data?.map((ele)=>{
    let arr = response?.data?.map((ele, ind) => {
      // console.log("46546sdjfbjsdfu", ele)
    
      return {
        sl: ind + 1,
        name: ele?.name,
        email: ele?.email,
        experience: ele?.experience,
        phoneNo: ele?.phoneNo,
        skills: ele?.skills?.join(', '),
        location: ele?.location,
        action:
        <div style={{ display: "flex", flexDirection: "coloum" }}>
          <div><i class="fa-regular fa-eye" title="View CV" onClick={()=>{setModal(true); handleViewCVmodal(ele)}} style={{
              height: "20px",
              width: "20px",
              cursor: "pointer",
              marginRight: "20px",
            }}></i></div>
          <svg
            onClick={(e) => onEdit(e,ele)}
            style={{
              height: "20px",
              width: "20px",
              cursor: "pointer",
              marginRight: "20px",
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-pencil-square"
            viewBox="0 0 16 16"
          >
            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
            <path
              fill-rule="evenodd"
              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
            />
          </svg>
          <svg
            onClick={() => onDelete(ele?._id)}
            style={{
              color: "red",
              height: "20px",
              cursor: "pointer",
              width: "20px",
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-trash3"
            viewBox="0 0 16 16"
          >
            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
          </svg>
        </div>
      };
    });
    //  console.log("dvbfghsv",arr)
    setAcoountRecruiterData(arr);
  };

  const handleViewCVmodal = (data) =>{
  console.log("xvmnbjhwryuie54",data)
  setUploadedCVdata(data)
  }

  useEffect(() => {
    if (location?.pathname == "/view-job-upload-CV") {
      console.log("location", location?.state);
      getSingleJobDetails(location?.state?._id);
      setId(location?.state?._id);
      resumeData(location?.state?._id);
    
    }
  }, [location , id]);

  const getSingleJobDetails = async (id) => {
    setLoading(true);
    const res = await HttpClient.requestData(`job/${id}`, "GET");
    console.log("RESFDSFFDSFSF", res);
    if (res && res?.status) {
      // console.log("res");
      setSingleData(res?.data[0]);
      // res?.data[0]?.ResumesData[0] &&
      //   setUploadedCV(res?.data[0]?.ResumesData[0]?.resume);
      res?.data[0]?.ResumesData[0] &&
        setCountCV(res?.data[0]?.ResumesData[0]?.resume);

      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
 const phoneRegex = /^[0-9]{10}$/;
  const SubmitHandler = async (e) => {
    e.preventDefault();

    let data = {
      jobId: id,
      resume: uploadedCV,
      name: recruiterData?.name,
      email: recruiterData?.email,
      experience: recruiterData?.experience,
      phoneNo: recruiterData?.phoneNo,
      skills: recruiterData?.skills,
      location: recruiterData?.location,
    };

    // setAcoountRecruiterData(data)

    // console.log("hgfdsydgwua", data);

    if (!recruiterData?.name) {
      return toast.error("Name field is required");
    } else if (!recruiterData?.email) {
      return toast.error("Email field is required");
    } else if (
      recruiterData?.email != "" &&
      !recruiterData?.email.match(mailformat)
    ) {
      return toast.error("Please enter valid email");
    } else if (!recruiterData?.experience) {
      return toast.error('Experience field is required');
    } else if (!recruiterData?.phoneNo) {
      return toast.error('Phone No. is required');
    } else if (!phoneRegex.test(recruiterData?.phoneNo)) {
      return toast.error('Please enter a valid 10-digit phone number');
    } else if (!recruiterData?.skills || recruiterData?.skills.length === 0) {
      return toast.error('Skills field is required');
    } else if (!recruiterData?.location) {
      return toast.error('Location field is required');
    } else if (uploadedCV?.length > 0) {
      // const res = await HttpClient.requestData("post-resume", "POST", recData);
      const res = await HttpClient.requestData('post-resume', 'POST', data);
      if (res?.status) {
        toast.success('CV uploaded successfully');
        let file = document.querySelector('#doc');
        file.value = '';
        // setUploadedCV([]);
        setUploadedCV('');
        setRecruiterdata(recruitData);
        id && resumeData(id);
      } else {
        toast.error('Uploaded error');
      }
    } else {
      toast.error('Please upload cv ');
    }

    // if (uploadedCV?.length > 0) {
    //   const res = await HttpClient.requestData("post-resume", "POST", data);
    //   if (res?.status) {
    //     toast.success("CV uploaded successfully");
    //     let file = document.querySelector("#doc");
    //     file.value = "";
    //     // setUploadedCV([]);
    //     setUploadedCV(recruiterData);
    //   } else {
    //     toast.error("Uploaded error");
    //   }
    // } else {
    //   toast.error("Please upload cv ");
    // }
  };

  const UpdateCV = async (e) => {
    e.preventDefault();

    let data = {
      jobId: id,
      resume: uploadedCV,
      name: recruiterData?.name,
      email: recruiterData?.email,
      experience: recruiterData?.experience,
      phoneNo: recruiterData?.phoneNo,
      skills: recruiterData?.skills,
      location: recruiterData?.location,
    };



    if (!recruiterData?.name) {
      return toast.error("Name field is required");
    } else if (!recruiterData?.email) {
      return toast.error("Email field is required");
    } else if (
      recruiterData?.email != "" &&
      !recruiterData?.email.match(mailformat)
    ) {
      return toast.error("Please enter valid email");
    } else if (!recruiterData?.experience) {
      return toast.error('Experience field is required');
    } else if (!recruiterData?.phoneNo) {
      return toast.error('Phone No. is required');
    } else if (!phoneRegex.test(recruiterData?.phoneNo)) {
      return toast.error('Please enter a valid 10-digit phone number');
    } else if (!recruiterData?.skills || recruiterData?.skills.length === 0) {
      return toast.error('Skills field is required');
    } else if (!recruiterData?.location) {
      return toast.error('Location field is required');
    } else if (uploadedCV?.length > 0) {
      // const res = await HttpClient.requestData("post-resume", "POST", recData);
      const res = await HttpClient.requestData(`update-resume/${ResumeId}`, 'PUT', data);
      if (res?.status) {
        toast.success('Uploaded successfully');
        let file = document.querySelector('#doc');
        file.value = '';
        setHide(true);
        // setUploadedCV([]);
        setUploadedCV('');
        setRecruiterdata(recruitData);
      id &&   resumeData(id);
      } else {
        toast.error('Uploaded error');
      }
    } else {
      toast.error('Please upload cv ');
    }

    // if (uploadedCV?.length > 0) {
    //   const res = await HttpClient.requestData("post-resume", "POST", data);
    //   if (res?.status) {
    //     toast.success("CV uploaded successfully");
    //     let file = document.querySelector("#doc");
    //     file.value = "";
    //     // setUploadedCV([]);
    //     setUploadedCV(recruiterData);
    //   } else {
    //     toast.error("Uploaded error");
    //   }
    // } else {
    //   toast.error("Please upload cv ");
    // }
  };



  const handleChange = async (e) => {
    setImgLoader(true);
    let file = e.target.files[0];
    let data = new FormData();
    data.append('image', file);

    // Validate if the selected file has an allowed type
    if (file && allowedFileTypes?.includes(file?.type)) {
      let res = await HttpClient.fileUplode('emp-image-upload', 'POST', data);
      if (res && res?.status) {
        setUploadedCV(`https://admin.cygnuspro.com/${res?.data}`);
      } else {
        setImgLoader(false);
        toast?.error(res?.message || 'something wrong');
      }
    } else {

      toast.error('Please select a valid PDF, DOC, or DOCX file.');
       let filess = document.querySelector('#doc');
       filess.value = '';
    }

    
    setImgLoader(false);
  };

  console.log(singleData, "singleDatatata");

  // const emailValidation = () => {
  // if (recruiterData?.email === '') {
  //   setError('Email Field cannot be blank.');
  //   alert('Email Field cannot be blank.');
  // } else if (recruiterData?.email != '' && !recruiterData?.email.match(mailformat)) {
  //   setError('Please enter valid email');
  //   alert('Please enter valid email');
  // } else {
  //   setError('');
  // }
  // };

  const HandleChange = (e) => {
    // e.preventDefault()
    setRecruiterdata({
      ...recruiterData,
      [e.target.name]: e.target.value,
    });
  };

  // const onClickGetData = (e) => {
  //   e.preventDefault();
  //   if (recruiterData?.email === "") {
  //     setError("Email Field cannot be blank.");
  //     alert("Email Field cannot be blank.");
  //   } else if (
  //     recruiterData?.email != "" &&
  //     !recruiterData?.email.match(mailformat)
  //   ) {
  //     setError("Please enter valid email");
  //     alert("Please enter valid email");
  //   } else {
  //     setError("");
  //     alert("data submitted successfully...");
  //     console.log("recrecruitData", recruiterData);
  //   }

  //   // setCountCV(recruiterData)
  // };

  const columns = [
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          SL
        </div>
      ),
      selector: (row) => row.sl,
      sortable: true,
      width: "80px",
    },

    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Name
        </div>
      ),
      selector: (row) => row.name,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          {" "}
          Email
        </div>
      ),
      selector: (row) => row.email,
      width: "15rem",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Experiene
        </div>
      ),
      selector: (row) => row.experience,
      width: "8rem",
    },

    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          {" "}
          Phone No.
        </div>
      ),
      selector: (row) => row.phoneNo,
      width: "12rem",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Skills
        </div>
      ),
      selector: (row) => row.skills,
      width: "12rem",
    },

    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          {" "}
          Location
        </div>
      ),
      selector: (row) => row.location,
      width: "10rem",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          {" "}
          Action
        </div>
      ),
      selector: (row) => row.action,
      width: "10rem",
    },
  ];

  // const fetchAllJobList = async (id) => {
  //   let res = await HttpClient.requestData(`job-added-by/${id}`, "GET");
  //   console.log("RESPONSE", res);

  //   if (res && res?.status) {
  //     let arr = res?.data?.map((item, index) => {
  //       return {
  //         sl: index + 1,
  //         name: item?.name,
  //         email: item?.email,
  //         experience: item?.experience,
  //         phoneNo: item?.phoneNo,
  //         skills: item?.skills,
  //         location: item?.location,
          
  //           // <div>
  //           //   <svg
  //           //     onClick={(e) => onEdit(e, item)}
  //           //     style={{
  //           //       height: "20px",
  //           //       width: "20px",
  //           //       cursor: "pointer",
  //           //       marginRight: "20px",
  //           //     }}
  //           //     xmlns="http://www.w3.org/2000/svg"
  //           //     width="16"
  //           //     height="16"
  //           //     fill="currentColor"
  //           //     className="bi bi-pencil-square"
  //           //     viewBox="0 0 16 16"
  //           //   >
  //           //     <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
  //           //     <path
  //           //       fill-rule="evenodd"
  //           //       d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
  //           //     />
  //           //   </svg>
  //           // </div>
         

  //         // Action:
  //         // <div style={{ display: "flex", flexDirection: "coloum" }}>
  //         //   <svg
  //         //     onClick={(e) => onEdit(e, item)}
  //         //     style={{
  //         //       height: "20px",
  //         //       width: "20px",
  //         //       cursor: "pointer",
  //         //       marginRight: "20px",
  //         //     }}
  //         //     xmlns="http://www.w3.org/2000/svg"
  //         //     width="16"
  //         //     height="16"
  //         //     fill="currentColor"
  //         //     className="bi bi-pencil-square"
  //         //     viewBox="0 0 16 16"
  //         //   >
  //         //     <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
  //         //     <path
  //         //       fill-rule="evenodd"
  //         //       d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
  //         //     />
  //         //   </svg>
  //         //   <svg
  //         //     onClick={() => onDelete(item?._id)}
  //         //     style={{
  //         //       color: "red",
  //         //       height: "20px",
  //         //       cursor: "pointer",
  //         //       width: "20px",
  //         //     }}
  //         //     xmlns="http://www.w3.org/2000/svg"
  //         //     width="16"
  //         //     height="16"
  //         //     fill="currentColor"
  //         //     className="bi bi-trash3"
  //         //     viewBox="0 0 16 16"
  //         //   >
  //         //     <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
  //         //   </svg>
  //         // </div>
  //         // item?.index + 1
  //         // ,
  //       };
  //     });
  //     setAcoountRecruiterData(arr);
  //   }
  // };

  const onEdit = (e, item) => {
    console.log("fgdgdfg", item);

    setResumeId(item?._id)
    e.preventDefault();
    setUploadedCV(item?.resume)
    
    setRecruiterdata(item);
  
    setHide(false);
  };

  const onDelete = async (ids) => {
    // console.log("deleteId", id);

    Swal.fire({
      title: "Are you sure?",

      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        HttpClient.requestData(`delete-resume/${ids}`, "PUT")
          .then((res) => {
            if (res && res.status) {
              toast.success("Deleted Successfully");
             id && resumeData(id)
              // recruiterData(id)
            } else {
              toast.error("Failed to Delete the Item ");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  return (
    <div style={{ padding: '20px 30px' }}>
      {/* <div className="Main">
        <div className="leftsection">
          <div className="topleft">
            <h5>
              <span>Job Title :</span> {singleData?.jobTitle}
            </h5>
            {singleData?.designation && (
              <h5>
                <span>Designation :</span> {singleData?.designation}
              </h5>
            )}
            <h5>
              <span>Employment Type :</span> {singleData?.employmentType}
            </h5>
            <h5>
              <span>Client :</span> {singleData?.department}
            </h5>
            <h5>
              <span>Key Skills:</span> {singleData?.keySkills?.join(',')}
            </h5>
            {singleData?.roleCategory && (
              <h5>
                <span>Role Category :</span> {singleData?.roleCategory}
              </h5>
            )}
            <h5>
              <span>Role:</span> {singleData?.role}
            </h5>
          </div>
          <div className="topright">
            <h5>
              <span>Work Mode:</span> {singleData?.workMode}
            </h5>
            <h5>
              <span>Job Location :</span> {singleData?.jobLocation?.join(', ')}
            </h5>
            <h5>
              <span>
                Experience{' '}
                
                :
              </span>{' '}
              {singleData?.experience}
            </h5> 
            <h5>
              <span>
                Salary{' '}
                <span
                  style={{
                    fontSize: '80%',
                    fontWeight: 'normal',
                  }}
                >
                  ( per Annum )
                </span>
                :
              </span>{' '}
              {singleData?.salary}
            </h5>
          </div>
        </div>
        <div className="rightsection">
          <div className="left_leftsde">
            {singleData?.openings && (
              <h5>
                <span>Openings:</span> {singleData?.openings}
              </h5>
            )} 
            <h5>
              <span>Description:</span> {singleData?.description}
            </h5>
            {singleData?.industryType && (
              <h5>
                <span>IndustryType:</span> {singleData?.industryType}
              </h5>
            )}
            {singleData?.education?.length > 0 && (
              <h5>
                <span>Education:</span> {`${singleData?.education},`}
              </h5>
            )}
            <h5>
              <span>JobCreaterType:</span> {singleData?.jobCreaterType}
            </h5>
          </div>
        </div>
      </div> */}

      <div>
        <div className="row" style={{ padding: '20px' }}>
          <div className="form-group col-md-6 col-12">
            <label for="exampleInputEmail1">
              Name<span style={{ color: 'red' }}> *</span>
            </label>
            <input
              type="text"
              name="name"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              value={recruiterData?.name}
              onChange={e => HandleChange(e)}
              placeholder="Enter full Name"
              style={{ height: '2em', fontSize: '20px' }}
            />
          </div>
          <div className="form-group col-md-6 col-12">
            <label for="exampleInputEmail1">
              Email<span style={{ color: 'red' }}> *</span>
            </label>
            <input
              type="text"
              name="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              value={recruiterData?.email}
              onChange={e => HandleChange(e)}
              placeholder="Enter your Email"
              style={{ height: '2em', fontSize: '20px' }}
            />
          </div>
          <div className="form-group col-md-6 col-12">
            <label for="exampleInputEmail1">
              Experience<span style={{ color: 'red' }}> *</span>
            </label>
            <input
              type="text"
              // min="0"
              oninput="validity.valid||(value='');"
              name="experience"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              value={recruiterData?.experience}
              placeholder="Enter your Experience..."
              style={{ height: '2em', fontSize: '20px' }}
              onChange={e => {
                // if (e.target.value > 0) {
                  setRecruiterdata({
                    ...recruiterData,
                    experience: e.target.value,
                  });
                // } 
                // else {
                //   toast.error('Experience field cannot be negative or empty');
                //   setRecruiterdata({
                //     ...recruiterData,
                //     experience: '',
                //   });
                // }
              }}
            />
          </div>
          <div className="form-group col-md-6 col-12">
            <label for="exampleInputEmail1">
              Phone No.<span style={{ color: 'red' }}> *</span>
            </label>
            <input
              type="number"
              name="phoneNo"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              value={recruiterData?.phoneNo}
              onChange={e => HandleChange(e)}
              placeholder="Enter your Phone No..."
              style={{ height: '2em', fontSize: '20px' }}
            />
          </div>
          <div className="form-group col-12" style={{ marginBottom: '6em' }}>
            <SelectableInput
              className="form-group selectInput"
              title=" Skills "
              subTitle="Enter one or more job skill"
              name="skills"
              value={recruiterData?.skills}
              onChange={val => {
                setRecruiterdata({
                  ...recruiterData,
                  skills: val,
                });
              }}
              placeholder="Enter keySkill(s) "
            />
          </div>
          <span style={{ color: 'red' }}>Press enter after adding each skill .</span>
          <div className="form-group col-12" style={{}}>
            <label for="exampleInputEmail1">
              Location<span style={{ color: 'red' }}> *</span>
            </label>
            <input
              type="text"
              name="location"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              value={recruiterData?.location}
              onChange={e => HandleChange(e)}
              placeholder="Enter your Location..."
              style={{ height: '2em', fontSize: '20px' }}
            />
          </div>
        </div>
      </div>

      <hr style={{ color: '#999', height: '0.2px' }} />
      <div className="col">
        <label htmlFor="formGroupExampleInput">
          Upload CV<span style={{ color: 'red' }}> *</span>
        </label>
        <input
          type="file"
          className="form-control"
          id="doc"
          placeholder="Image"
          onChange={handleChange}
          name="image"
          accept=".pdf,.doc,.docx"
          // multiple
        />

        {/* picture */}
        <div style={{ display: 'flex', marginTop: '12px' }}>
          {imageLoader && <ImageLoader />}
          {uploadedCV && (
            <span
              // key={i}
              style={{
                display: 'flex',
              }}
            >
              <img
                src="https://www.computerhope.com/jargon/d/doc.png"
                className="img-fluid m-1"
                alt="Responsive image"
                style={{ height: '5rem', width: '5rem' }}
              />
              <span
                style={{ fontSize: '25px', cursor: 'pointer', color: 'red' }}
                onClick={() => {
                  // formValue

                  setUploadedCV('');
                  let file = document?.querySelector('#doc');
                  file.value = '';
                  // setFormValue(prev => ({ ...prev, image: imgArr }));
                }}
              >
                x
              </span>
            </span>
          )}
        </div>

        {hide ? (
          <button style={{ marginTop: '19px' }} className="btn btn-primary" onClick={SubmitHandler}>
            Submit
          </button>
        ) : (
          <button style={{ marginTop: '19px' }} className="btn btn-primary" onClick={UpdateCV}>
            Update
          </button>
        )}

        <div style={{ marginTop: '5em' }}>
          <DataTable columns={columns} data={AcoountRecruiterData} pagination />
        </div>

        <div>
                <Modal size="lg"
                    isOpen={modal}
                    toggle={() => setModal(!modal)}
                >
                    <ModalHeader toggle={() => setModal(!modal)}>
                    </ModalHeader>
                    <ModalBody>
                    <div className="Main">
                            <div className="leftsection">
                            <div className="topleft">
                                    <h5>
                                        <span>Name :</span> {UploadedCVdata?.name}
                                    </h5> 
                                    <h5>
                                        <span>Email :</span> {UploadedCVdata?.email}
                                    </h5> 
                                    <h5>
                                        <span>Experience :</span> {UploadedCVdata?.experience}
                                    </h5> 
                                    <h5>
                                        <span>Phone No :</span> {UploadedCVdata?.phoneNo}
                                    </h5> 
                                    <h5>
                                        <span>Skills :</span> {UploadedCVdata?.skills?.join(', ')}
                                    </h5> 
                                    <h5>
                                        <span>Location :</span> {UploadedCVdata?.location}
                                    </h5> 
                                </div>
                            </div> 
                        </div>   
                    </ModalBody>
                    <ModalFooter>
                    <div className="box">
                    <div className="right">
                      <button
                        type="button"
                        style={{
                          fontWeight: 'bold',
                          fontSize: '20px',
                        }}
                        class="btn btn-outline-success"
                        title="Download CV"
                      >
                        <Link to={UploadedCVdata?.resume} className="downloadLink">
                          Download CV <IoMdDownload />
                        </Link>  
                      </button>
                    </div>
                  </div>
                    </ModalFooter>
                </Modal>
            </div>
      </div>
    </div>
  );
}; 
export default ViewJobs;
