import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Viewjobs.css";
import HttpClient from "../../components/HttpClient";
import { useLocation } from "react-router-dom";
import { useScrollTrigger } from "@mui/material";
import toast from "react-hot-toast";
import { IoMdDownload } from 'react-icons/io';
import DocViewer from "react-doc-viewer";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const ViewJobList = () => {
  const location = useLocation();
  const [jobId, setJobId] = useState("")
  const [cvData, setCvData] = useState([]);
  const [docFile, setDocFile] = useState();
  const [show, setShow] = useState(false)
  const [modal, setModal] = useState(false);
  const [CvIndData, setIndCvData] = useState ([])
  console.log('CVDATA', cvData);

  useEffect(() => {
    if (location?.pathname == '/hr-manager-view-jobs-list') {
      // console.log("Location", location?.state);
      AllCv(location?.state);
      // setJobId(location?.state);

    }
  }, [])

  const viewCV = (docs) => {
    setShow(true)
    setDocFile(docs)


  }

  const AllCv = async (id) => {

    let data = {
      jobId: id,
    };

    let result = await HttpClient.requestData('get-resume', 'POST', data);
    console.log("result", result);
    if (result && result.status) {
      setCvData(result?.data);
    } else {
      toast.error(result.message);
    }
  }

  const handleCvID = (ele) =>{
    console.log("mnbjhkyui678",ele)
    // if (ele?._id){
      let data = {
        id : ele?._id,
        name : ele?.name,
        email : ele?.email,
        location : ele?.location,
        phoneNo : ele?.phoneNo,
        experience : ele?.experience,
        skills : ele?.skills,
        resume : ele?.resume
      }
      setIndCvData(data)
      console.log("mg09bdfng578ui",data)
    // }
  }
  return (
    <>


      <h1 className="my-3">View CV List</h1>

      <div className="row" 
        // style={{display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap",}} 
      >

        {cvData?.map((ele, index) => {
          console.log("cvData675",ele)
            return (
              <div className="col-lg-4 col-md-6 col-12">
                <div className=" oneDiv" style={{ margin: '0 20px 50px', backgroundColor: '#fff', padding: '0 20px' }}>
                  <div className="box">
                    <div
                      className=""
                      style={{
                        display: 'grid',
                        padding: '10px',
                      }}
                    >
                      <div style={{ position: 'relative', marginTop: '10px' }}>
                        <h1 style={{ color: '#000', fontSize: '24px' }}>{ele?.name}</h1>
                      </div>

                      <h5>
                        <span>Email :</span> {ele?.email}
                      </h5>
                      <h5>
                        <span>Location :</span> {ele?.location}
                      </h5>
                      <h5>
                        <span>PhoneNo :</span> {ele?.phoneNo}
                      </h5>
                      <h5>
                        <span>Experience :</span> {ele?.experience}
                      </h5>
                      <h5>
                        <span>Skills :</span> {ele?.skills?.join(', ')}
                      </h5>
                    </div>
                  </div>

                  <div className="box">
                    <div className="right">
                      <button
                        type="button"
                        style={{
                          fontWeight: 'bold',
                          fontSize: '20px',
                        }}
                        class="btn btn-outline-success"
                      >
                        <Link to={ele?.resume} title="Download CV">
                          Download CV <IoMdDownload />
                        </Link>
                      </button>

                        <button class="btn btn-outline-success" 
                        title="View CV"
                        onClick={() => {
                          setModal(true); 
                          handleCvID(ele)
                        }}
                        style={{
                          // fontWeight: 'bold',
                          marginLeft:"30px",
                          fontSize: '18px',
                        }}><i class="fa-solid fa-eye"></i>
                        </button> 
                    </div>
                  </div>

                  <div> 
                  <Modal  size="lg"
                        isOpen={modal}
                        toggle={() => setModal(!modal)}
                      >
                        <ModalHeader toggle={() => setModal(!modal)}> 
                        </ModalHeader>
                        <ModalBody>
                        <div
                      className=""
                      style={{
                        display: 'grid',
                        padding: '10px',
                      }}
                    >
                      <div style={{ position: 'relative', marginTop: '10px' }}>
                        <h1 style={{ color: '#000', fontSize: '24px' }}>{CvIndData?.name}</h1>
                      </div>

                      <h5>
                        <span>Email :</span> {CvIndData?.email}
                      </h5>
                      <h5>
                        <span>Location :</span> {CvIndData?.location}
                      </h5>
                      <h5>
                        <span>PhoneNo :</span> {CvIndData?.phoneNo}
                      </h5>
                      <h5>
                        <span>Experience :</span> {CvIndData?.experience}
                      </h5>
                      <h5>
                        <span>Skills :</span> {CvIndData?.skills?.join(', ')}
                      </h5>
                    </div>
                        </ModalBody>
                        <hr/>
                        <div className="right" style={{marginBottom:"10px"}}>

                        <button
                        type="button"
                        style={{
                          fontWeight: 'bold',
                          fontSize: '20px',
                        }}
                        class="btn btn-outline-success"
                        >
                        <Link to={CvIndData?.resume} title="Download CV">
                          Download CV <IoMdDownload />
                        </Link>
                      </button>
                        </div>
                      </Modal>
                  </div>
                </div>
              </div>
            );
        })}



      </div>

      {/* {show && docFile && (<iframe src={docFile} width="800" height="600" style="border: 1px solid #ccc;"></iframe>)} */}
    </>
  );
};

export default ViewJobList;
