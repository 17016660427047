import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { IoMdDownload } from 'react-icons/io';


const JobReport = () => {
    const [data, setData] = useState('')
  const location = useLocation();

  useEffect(() => {
    if (location?.pathname == "/view-job") {
      console.log("location", location?.state);
      setData( location?.state)
    //   getSingleJobDetails(location?.state?.id); 
    
    }
  }, [location]);
//   resume
  return (
    <div className="Main">
    <div className="leftsection">
    <div className="topleft">
            <h5>
                <span>Name :</span> {data?.name}
            </h5> 
            <h5>
                <span>Email :</span> {data?.email}
            </h5> 
            <h5>
                <span>Experience :</span> {data?.experience}
            </h5> 
            <h5>
                <span>Phone No :</span> {data?.phoneNo}
            </h5> 
            <h5>
                <span>Skills :</span> {data?.skills?.join(', ')}
            </h5> 
            <h5>
                <span>Location :</span> {data?.location}
            </h5> 
            <h5>
                <span>Recruiter :</span> {data?.userName}
            </h5> 
        </div> 
        <div className="box">
                    <div className="right">
                      <button
                        type="button"
                        style={{
                          fontWeight: 'bold',
                          fontSize: '20px',
                        }}
                        class="btn btn-outline-success"
                        title="Download CV"
                      >
                        <Link to={data?.resume} className="downloadLink">
                          Download CV <IoMdDownload />
                        </Link>  
                      </button>
                    </div>
                  </div>
    </div> 
</div> 
  )
}

export default JobReport