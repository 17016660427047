import React, { useEffect, useMemo, useState } from "react";
import HttpClient from "../../components/HttpClient";
import SelectableInput from "./SelectableInput";
// import '../pages/Login.css';
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import Modal from "react-modal";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useStateContext } from "../../contexts/ContextProvider";

const client = [
  { value: "Kolkata", },
  { value: "Bangaluru", },
  { value: "Hydrabad", },
  { value: "Chennai", },
  { value: "London", },
  { value: "Pune", },
  { value: "Mumbai", },
  { value: "Jaipur", },
];

const INITIAl = {
  jobTitle: '',
  designation: '',
  employmentType: '',
  keySkills: [],
  department: '',
  roleCategory: '',
  role: '',
  workMode: '',
  jobLocation: [],
  experience: '',
  salary: '',
  openings: '',
  description: '',
  industryType: '',
  education: [],
  // assignedRecruiter:"",
};

// modal custom style
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30em",
    height: "25em",
  },
};
// Modal.setAppElement();



const AddAndManageJob = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpen1, setIsOpen1] = useState(false);
  const [hide, setHide] = useState(true);
  const [jobId, setJobId] = useState("")
  const [summery, setSummery] = useState("");
  const [jobDetail, setJobDetails] = useState(INITIAl);
  const [recruiterData, setRecruiterData] = useState([]);
  const [managerData, setManagerData] = useState([]);
  const [recruiterName, setRecruiterName] = useState("");
  const [assignedRecruitersName, setAssignedRecruitersName] = useState([]);
  const [Recruiter, setRecruiter] = useState([]);
  const [assignedManagersName, setAssignedManagersName] = useState([]);
  const [Manager, SetManager] = useState([]);
  // console.log('recruiterName', Recruiter);
  const [serchTag, setSearchTag] = useState([]);
  const [hrId, setHrId] = useState("");
  const [id, setId] = useState("");
  const [selectRecruiter, setselectRecruiter] = useState()
  const { userProfileData } = useStateContext();

  const [AcoountManagerData, setAccountManagerData] = useState([]);

  const [clientData, setClientData] = useState([])
  const [filteredClients, setFilteredClients] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  // console.log('assignedRecruitersName', assignedRecruitersName);
  useEffect(() => {
    userProfileData && fetchAllJobList(userProfileData?._id);
    fetchRecruiter();
    FetchManagerData();
  }, [userProfileData]);

  const navigate = useNavigate();

  const fetchRecruiter = async () => {
    let arr = [];
    let res = await HttpClient.requestData('profileAdded', 'GET');
    if (res && res?.status) {
      res?.data?.forEach((ele) => {
        if (ele?.userType === "Recruiter") {
          arr.push({ label: ele?.fullName, value: ele?._id });
        }
      })
      arr && setRecruiterData(arr);
    }
  }

  const FetchManagerData = async () => {
    let arr = [];
    let res = await HttpClient.requestData('profileAdded', 'GET');

    if (res && res?.status) {
      res?.data?.forEach(ele => {
        if (ele?.userType === 'AccountManager') {
          arr.push({ label: ele?.fullName, value: ele?._id });
        }
      });
      arr && setManagerData(arr);
    }
  };


  const selectedRecruiter = (e) => {
    console.log()
  }

  const openModal = async (item) => {
    let arrRecruiter = [];
    let res = await HttpClient.requestData('profileAdded', 'GET');
    if (res && res?.status) {
      res?.data?.forEach((ele) => {
        if (ele?.userType === "Recruiter") {
          arrRecruiter.push({ label: ele?.fullName, value: ele?._id });
        }
      })
    }
    setRecruiter(item?.assignedRecruiter);
    setJobId(item?._id);
    let arr = [];
    arrRecruiter &&
      item?.assignedRecruiter?.forEach((ele, index) => {
        arrRecruiter?.forEach((element, index) => {
          if (ele === element?.value) {
            arr.push(element);
          }
        });
      });
    setAssignedRecruitersName(arr);

    setIsOpen(true);
  }

  const openModal1 = async (item) => {
    let arrManager = [];
    let res = await HttpClient.requestData('profileAdded', 'GET');

    if (res && res?.status) {
      res?.data?.forEach(ele => {
        if (ele?.userType === 'AccountManager') {
          arrManager.push({ label: ele?.fullName, value: ele?._id });
        }
      });
    }
    setJobId(item?._id);
    let arr = [];
    item?.assignedAM &&
      item?.assignedAM?.forEach((ele, index) => {
        arrManager?.forEach((element, index) => {
          if (ele === element?.value) {
            arr.push(element);
          }
        });
      });
    setAssignedManagersName(arr);
    item && SetManager(item?.assignedAM);
    setIsOpen1(true);
  }

  const HandleRecruiter = e => {
    setAssignedRecruitersName(e);
    let arr = [];
    e?.map(item => {
      return arr.push(item?.value);
    });
    setRecruiter(arr);
  };

  const HandleManager = e => {
    setAssignedManagersName(e);
    let arr = [];
    e?.map(item => {
      return arr.push(item?.value);
    });
    SetManager(arr);
  };



  function closeModal() {
    setIsOpen(false);

  }
  function closeModal1() {
    setIsOpen1(false);
  }

  const FindRecruiterName = () => {
    recruiterData && jobDetail?.assignedRecruiter && recruiterData?.forEach((ele) => {
      if (ele?._id === jobDetail?.assignedRecruiter) {
        setRecruiterName(ele?.fullName)
      }
    })
  }

  // >>>>Fetch Client Data<<<<
  const fetchdepartment = async () => {
    const res = await HttpClient.requestData("fetch-client", "GET")
    if (res && res?.status) {
      setClientData(res?.data)
      console.log("5df1s651", res?.data)
    } else {
      setClientData([])
    }
  }

  useEffect(() => {
    fetchdepartment()
  }, [])

  const onsubmit = async (e) => {
    e.preventDefault();
    if (!jobDetail?.jobTitle) {
      return toast.error('JobTitle field is required');
    }
    if (!jobDetail?.employmentType) {
      return toast.error('EmploymentType field is required');
    }
    else if (jobDetail?.keySkills?.length == 0) {
      return toast.error('KeySkills field is required');
    }
    else if (!jobDetail?.department) {
      return toast.error('Client field is required');
    }
    // else if (!jobDetail?.role) {
    //   return toast.error('Role field is required');
    // } 
    else if (!jobDetail?.workMode) {
      return toast.error('WorkMode field is required');
    } else if (jobDetail?.jobLocation?.length == 0) {
      return toast.error('JobLocation field is required');
    } else if (!jobDetail?.experience) {
      return toast.error('Experience field is required');
    } else if (!jobDetail?.salary) {
      return toast.error('Salary/rate field is required');
    } else if (!jobDetail?.description) {
      return toast.error('Description field is required');
    }
    console.log("d5sgf51", jobDetail)
    // return
    try {
      let response = await HttpClient.requestData('createJob', 'POST', jobDetail);

      if (response?.status) {
        setJobDetails(response?.data);
        toast.success('Job added successfully');
        setJobDetails(INITIAl);
        setRecruiterName("")
        userProfileData && fetchAllJobList(userProfileData?._id);
      } else {
        toast.error("Error fetching job details");
      }
    } catch (error) {
      console.error("API request error:", error);
    }

  };

  // const HandleChange = e => {
  //   const { name, value } = e.target;

  //   // Convert the salary value to a number if the field is "salary"
  //   const newValue = name === 'salary' ? parseFloat(value) : value;

  //   setJobDetails(prevJobDetail => ({
  //     ...prevJobDetail,
  //     [name]: newValue,
  //   }));
  // };

  const HandleChange = (e) => {
    setJobDetails({
      ...jobDetail,
      [e.target.name]: e.target.value,
    });
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setJobDetails({
      ...jobDetail,
      department: value,
    });


    // Filter clients based on input value
    if (value) {
      const filtered = clientData.filter(client =>
        client.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredClients(filtered);
      setShowSuggestions(true);
    } else {
      setFilteredClients([]);
      setShowSuggestions(false);
    }
  };
  const handleSuggestionClick = (suggestion) => {
    setJobDetails({
      ...jobDetail,
      department: suggestion,
    });
    setFilteredClients([]);
    setShowSuggestions(false);
  };



  const onUpdate = async e => {

    e.preventDefault();

    if (!jobDetail?.jobTitle) {
      return toast.error('JobTitle field is required');
    } else if (!jobDetail?.employmentType) {
      return toast.error('EmploymentType field is required');
    } else if (jobDetail?.keySkills?.length == 0) {
      return toast.error('KeySkills field is required');
    } else if (!jobDetail?.department) {
      return toast.error('Client field is required');
    }
    // else if (!jobDetail?.role) {
    //   return toast.error('Role field is required');
    // } 
    else if (!jobDetail?.workMode) {
      return toast.error('WorkMode field is required');
    } else if (jobDetail?.jobLocation?.length == 0) {
      return toast.error('JobLocation field is required');
    } else if (!jobDetail?.experience) {
      return toast.error('Experience field is required');
    } else if (!jobDetail?.salary) {
      return toast.error('Salary/rate field is required');
    } else if (!jobDetail?.description) {
      return toast.error('Description field is required');
    }

    let res = await HttpClient.requestData(`updateJob/${id}`, 'PUT', jobDetail);
    if (res && res?.status) {
      toast.success('Job updated successfully');
      setJobDetails(INITIAl);
      setRecruiterName("");
      userProfileData && fetchAllJobList(userProfileData?._id);
      setHide(true);
    } else {
      toast.error(res?.message);
    }

  };

  const AssignToRecruiter = async e => {
    e.preventDefault();
    if (Recruiter.length === 0) {
      toast.error('Please assign anyone recruiter');
    } else {
      let data = {
        assignedRecruiter: Recruiter,
      };
      let res = await HttpClient.requestData(`updateJob/${jobId}`, 'PUT', data);
      if (res && res?.status) {
        toast.success('Assigned successfully');
        setRecruiter([]);
        userProfileData && fetchAllJobList(userProfileData?._id);
        FindRecruiterName();
        closeModal();
      } else {
        toast.error(res?.message);
      }
    }
  };

  const AssignToManager = async e => {
    e.preventDefault();
    if (Manager.length < 1) {
      toast.error('Please assign anyone account manager');
    } else {
      let data = {
        assignedAM: Manager,
      };

      let res = await HttpClient.requestData(`updateJob/${jobId}`, 'PUT', data);
      if (res && res?.status) {
        toast.success('Assigned successfully');

        SetManager([]);

        userProfileData && fetchAllJobList(userProfileData?._id);
        FindRecruiterName();
        closeModal1();
      } else {
        toast.error(res?.message);
      }
    }
  };

  const fetchAllJobList = async (id) => {
    let res = await HttpClient.requestData(`job-added-by/${id}`, "GET");
    console.log("RES4646PONSE", res?.data);

    if (res && res?.status) {
      let arr = res?.data?.reverse()?.map((item, index) => {
        return {
          sl: index + 1,
          jobCreaterType: item?.jobCreaterType,
          department: item?.department,
          designation: item?.designation,
          education: item?.education,
          employmentType: item?.employmentType,
          experience: item?.experience,
          industryType: item?.industryType,
          jobCreaterType: item?.jobCreaterType,
          jobLocation: item?.jobLocation?.join(','),
          jobTitle: item?.jobTitle,
          keySkills: item?.keySkills?.join(','),
          openings: item?.openings,
          role: item?.role,
          roleCategory: item?.roleCategory,
          salary: item?.salary,
          // recruiterName: item?.assignedRecruiterName,

          workMode: item?.workMode,
          recruiters: (
            <div
              style={{
                marginTop: '11px',
              }}
            >
              <button onClick={() => openModal(item)} type="button" className="btn btn-success">
                Assign to recruiter
              </button>
              {/* {item?.assignedRecruiterName.length >= 1 && <p>item?.assignedRecruiterName.join(',')</p>} */}
              {item?.assignedRecruiterName?.length > 0 && <p>{`${item?.assignedRecruiterName}`}</p>}
            </div>
          ),
          recruiterss: (
            <div
              style={{
                marginTop: '11px',
              }}
            >
              <button onClick={() => openModal1(item)} type="button" className="btn btn-success">
                Assign to Manager
              </button>
              {item?.assignedAmName?.length > 0 && <p>{`${item?.assignedAmName} `}</p>}
            </div>
          ),

          action: (
            <div style={{ display: 'flex', flexDirection: 'coloum' }}>
              <svg
                onClick={e => onEdit(e, item)}
                style={{
                  height: '20px',
                  width: '20px',
                  cursor: 'pointer',
                  marginRight: '20px',
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-pencil-square"
                viewBox="0 0 16 16"
              >
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path
                  fill-rule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                />
              </svg>
              <svg
                onClick={() => onDelete(item?._id)}
                style={{
                  color: 'red',
                  height: '20px',
                  cursor: 'pointer',
                  width: '20px',
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-trash3"
                viewBox="0 0 16 16"
              >
                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
              </svg>
            </div>
          ),
        };
      });
      setAccountManagerData(arr);
    }
  };

  const onDelete = async (id) => {
    console.log("deleteId", id);

    Swal.fire({
      title: "Are you sure?",
      // text: "You won't  to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        HttpClient.requestData(`job/${id}`, "DELETE", jobDetail)
          .then((res) => {
            if (res && res.status) {
              toast.success("Deleted Successfully");
              userProfileData && fetchAllJobList(userProfileData?._id);
            } else {
              toast.error("Failed to Delete the Item ");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const onEdit = (e, item) => {
    console.log("PASSSDSDF", item);
    e.preventDefault();


    setRecruiterName(item?.assignedRecruiterName);
    setJobDetails(item);
    setId(item?._id);
    setHide(false);
  };

  const columns = [
    {
      name: <div style={{ fontSize: '14px', color: '#495057', fontWeight: 'bolder' }}>SL</div>,
      selector: row => row.sl,
      sortable: true,
      width: '80px',
    },

    {
      name: <div style={{ fontSize: '14px', color: '#495057', fontWeight: 'bolder' }}>Job Creater Type</div>,
      selector: row => row.jobCreaterType,
      width: '10rem',
    },
    {
      name: <div style={{ fontSize: '14px', color: '#495057', fontWeight: 'bolder' }}> Job Title</div>,
      selector: row => row.jobTitle,
      width: '12rem',
    },

    {
      name: <div style={{ fontSize: '14px', color: '#495057', fontWeight: 'bolder' }}>Client</div>,
      selector: row => row.department,
      width: '8rem',
    },
    // {
    //   name: <div style={{ fontSize: '14px', color: '#495057', fontWeight: 'bolder' }}> Designation</div>,
    //   selector: row => row.designation,
    //   width: '12rem',
    // },
    {
      name: (
        <div
          style={{
            fontSize: '14px',
            color: '#495057',
            // marginLeft: '15px',
            fontWeight: 'bolder',
          }}
        >
          Employment Type
        </div>
      ),
      selector: row => row.employmentType,
      width: '10rem',
    },

    {
      name: <div style={{ fontSize: '14px', color: '#495057', fontWeight: 'bolder' }}> Experience</div>,
      selector: row => row.experience,
      width: '8rem',
    },

    // {
    //   name: <div style={{ fontSize: '14px', color: '#495057', fontWeight: 'bolder' }}> Job Location</div>,
    //   selector: row => row.jobLocation,
    // },
    {
      name: <div style={{ fontSize: '14px', color: '#495057', fontWeight: 'bolder' }}> Key Skills</div>,
      selector: row => row.keySkills,
      width: '12rem',
    },

    {
      name: <div style={{ fontSize: '14px', color: '#495057', fontWeight: 'bolder' }}> Role</div>,
      selector: row => row.role,
      width: '12rem',
    },

    {
      name: <div style={{ fontSize: '14px', color: '#495057', fontWeight: 'bolder' }}> Salary</div>,
      selector: row => row.salary,
      width: '10rem',
    },
    {
      name: <div style={{ fontSize: '14px', color: '#495057', fontWeight: 'bolder' }}> Work Mode</div>,
      selector: row => row.workMode,
      width: '8rem',
    },
    {
      name: <div style={{ fontSize: '14px', color: '#495057', fontWeight: 'bolder' }}> Assign to Recruiter</div>,
      selector: row => row.recruiters,
      width: '12rem',
      wrap: true,
    },
    {
      name: <div style={{ fontSize: '14px', color: '#495057', fontWeight: 'bolder' }}> Assign to Manager</div>,
      selector: row => row.recruiterss,
      width: '12rem',
      wrap: true,
    },
    {
      name: (
        <div
          style={{
            fontSize: '14px',
            color: '#495057',
            marginLeft: '15px',
            fontWeight: 'bolder',
          }}
        >
          Action
        </div>
      ),
      selector: row => row.action,
    },
  ];

  return (
    <>
      <div style={{ width: '100%', padding: '20px 30px' }}>
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div
                style={{
                  textAlign: 'center',
                  fontSize: '20px',
                  color: '#868e96',
                  margin: '35px',
                }}
                className="card-title"
              >
                {hide ? 'Add Job' : 'Update Job'}
              </div>

              <div className="row">
                <div className="form-group col-md-6 col-12">
                  <label for="exampleInputEmail1">
                    Job Title<span style={{ color: 'red' }}>*</span> :
                  </label>
                  <input
                    type="text"
                    name="jobTitle"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={jobDetail?.jobTitle}
                    onChange={e => HandleChange(e)}
                    placeholder="Enter full job title..."
                  />
                </div>
                <div className="form-group col-md-6 col-12">
                  <label for="exampleInputEmail1">Designation :</label>
                  <input
                    type="text"
                    className="form-control"
                    name="designation"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={jobDetail?.designation}
                    onChange={e => HandleChange(e)}
                    placeholder="Enter full designation..."
                  />
                </div>

                <div className="form-group col-md-6 col-12">
                  <label for="exampleInputEmail1">
                    Employment Type<span style={{ color: 'red' }}>*</span> :
                  </label>
                  {/* <input
                    type="text"
                    name="employmentType"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={jobDetail?.employmentType}
                    onChange={e => HandleChange(e)}
                    placeholder="Enter full employmentType..."
                  /> */}
                  <select
                    className="form-select"
                    aria-label="Disabled select example"
                    onChange={e => {
                      setJobDetails({
                        ...jobDetail,
                        employmentType: e.target.value,
                      });
                    }}
                    value={jobDetail?.employmentType}
                  >
                    <option value="">Select Employment Type...</option>
                    <option value="W2 employee">W2 employee</option>
                    <option value="C2C">C2C</option>
                    <option value="1099">1099</option>
                    <option value="FullTime">FullTime</option>
                    <option value="PartTime">PartTime</option>
                  </select>
                </div>
                <div className="form-group col-md-6 col-12">
                  <SelectableInput
                    title="KeySkills "
                    subTitle="Enter one or more skills"
                    value={jobDetail?.keySkills}
                    // value={serchTag}
                    // onChange={e => HandleChange(e)}
                    onChange={val => {
                      setJobDetails({
                        ...jobDetail,
                        keySkills: val,
                      });
                    }}
                    // onChange={(val) => setSearchTag(val)}
                    className="form-group"
                    placeholder="Enter key skill(s) "
                  />
                  {/* <small id="emailHelp" class="form-text text-muted">
                    Press enter after each skill .
                  </small> */}
                  <span style={{ color: 'red' }}>Press enter after adding each skill .</span>
                </div>

                  <div className="form-group col-md-6 col-12">
                    <label htmlFor="client-input">
                      Client<span style={{ color: 'red' }}>*</span> :
                    </label>
                    <div className="autocomplete-container" style={{ position: 'relative' }}>
                      <input
                        type="text"
                        name="department"
                        className="form-control"
                        id="client-input"
                        value={jobDetail.department}
                        onChange={handleInputChange}
                        placeholder="Enter or select a client"
                        onFocus={() => setShowSuggestions(true)}
                      />
                      {showSuggestions && filteredClients.length > 0 && (
                        <div className="suggestions-dropdown">
                          <ul>
                            {filteredClients.map((client, index) => (
                              <li
                                style={{ padding: "8px", cursor: "pointer" }}
                                key={index}
                                onClick={() => handleSuggestionClick(client)}
                              >
                                {client}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>

                <div className="form-group col-md-6 col-12">
                  <label for="exampleInputEmail1">Role Category :</label>
                  <input
                    type="text"
                    name="roleCategory"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={jobDetail?.roleCategory}
                    onChange={e => HandleChange(e)}
                    placeholder="Enter full role category"
                  />
                </div>
                <div className="form-group col-md-6 col-12">
                  <label for="exampleInputEmail1">
                    Role :
                  </label>
                  <input
                    type="text"
                    name="role"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={jobDetail?.role}
                    onChange={e => HandleChange(e)}
                    placeholder="Enter full role"
                  />
                </div>
                <div className="form-group col-md-6 col-12">
                  <label
                    for="exampleInputEmail1"
                    style={{
                      display: 'block',
                      marginBottom: '8px',
                      marginTop: '35px',
                    }}
                  >
                    Work Mode<span style={{ color: 'red' }}>*</span> :
                  </label>
                  <select
                    className="form-select"
                    aria-label="Disabled select example"
                    onChange={e => {
                      setJobDetails({
                        ...jobDetail,
                        workMode: e.target.value,
                      });
                    }}
                    value={jobDetail?.workMode}
                  >
                    <option value="">Select Work Mode...</option>
                    <option value="WFH">WFH</option>
                    <option value="On-Site">On-Site</option>
                    <option value="Hybrid">Hybrid</option>
                  </select>
                  {/* <div>
                <select
                  value={jobDetail?.workMode}
                  name="workMode"
                  onChange={(e) => HandleChange(e)}
                  className=" form-control"
                  id="floatingSelect"
                  aria-label="Floating label select example"
                >
                  <option value="" selected>
                    Select City
                  </option>
                  <option value="Kolkata">Kolkata</option>
                  <option value="Delhi">Delhi</option>
                  <option value="Mumbai">Mumbai</option>
                </select>
              </div> */}

                  {/* <input
                type="text"
                name="workMode"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={jobDetail?.workMode}
                onChange={(e) => HandleChange(e)}
                placeholder="Enter full workMode..."
              /> */}
                </div>
                {/* <div className="form-group col-md-6 col-12">
                  <label for="exampleInputEmail1">
                    JobLocation<span style={{ color: 'red' }}>*</span> :
                  </label>
                  <Select
                    isMulti
                    name="jobLocation"
                    options={options}
                    value={jobDetail?.jobLocation}
                    onChange={e => {
                      setJobDetails({
                        ...jobDetail,
                        jobLocation: e,
                      });
                    }}
                  />
                </div> */}

                <div className="form-group col-md-6 col-12">
                  <SelectableInput
                    title="Job Location "
                    subTitle="Enter one or more job location"
                    // value={jobDetail?.education}
                    value={jobDetail?.jobLocation}
                    onChange={val => {
                      setJobDetails({
                        ...jobDetail,
                        jobLocation: val,
                      });
                    }}
                    className="form-group"
                    placeholder="Enter jobLocation(s) "
                  />
                  {/* <small id="emailHelp" class="form-text text-muted">
                    Press enter after each job location .
                  </small> */}
                  <span style={{ color: 'red' }}>Press enter after adding each job location .</span>
                </div>
                <div className="form-group col-md-6 col-12">
                  <label for="exampleInputEmail1">
                    Experience{' '}
                    <span style={{ color: 'red' }}>*</span> :
                  </label>
                  <input
                    type="text"
                    name="experience"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={jobDetail?.experience}
                    onChange={e => {
                      setJobDetails({
                        ...jobDetail,
                        experience: e.target.value,
                      });
                    }}
                    placeholder="Enter full experience"
                  />
                </div>

                <div className="form-group col-md-6 col-12">
                  <label for="exampleInputEmail1">
                    Salary / Rate{' '}
                    <span
                      style={{
                        fontSize: '80%',
                        fontWeight: 'normal',
                      }}
                    >
                      ( per Annum )
                    </span>
                    <span style={{ color: 'red' }}>*</span> :
                  </label>
                  <input
                    type="text"
                    name="salary"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={jobDetail?.salary}
                    onChange={e => {
                      // if (e.target.value > 0) {
                      setJobDetails({
                        ...jobDetail,
                        salary: e.target.value,
                      });
                      // }
                      // else {
                      //   toast.error('Salary/rate field cannot be negative or empty');
                      //   setJobDetails({
                      //     ...jobDetail,
                      //     salary: '',
                      //   });
                      // }
                    }}
                    placeholder="Enter salary/rate..."
                  />
                </div>

                <div className="form-group col-md-6 col-12">
                  <label for="exampleInputEmail1">
                    Openings{' '}
                    <span
                      style={{
                        fontSize: '80%',
                        fontWeight: 'normal',
                      }}
                    >
                      ( in Nos )
                    </span>{' '}
                    :
                  </label>
                  <input
                    type="number"
                    name="openings"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={jobDetail?.openings}
                    onChange={e => {
                      if (e.target.value > 0) {
                        setJobDetails({
                          ...jobDetail,
                          openings: e.target.value,
                        });
                      } else {
                        toast.error('Openings field cannot be negative or empty');
                        setJobDetails({
                          ...jobDetail,
                          openings: '',
                        });
                      }
                    }}
                    placeholder="Enter full openings"
                  />
                </div>
                <div className="form-group ">
                  <label for="exampleInputEmail1">
                    Description<span style={{ color: 'red' }}>*</span> :
                  </label>
                  <textarea
                    type="text"
                    name="description"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={jobDetail?.description}
                    onChange={e => HandleChange(e)}
                    placeholder="Enter full description"
                  />
                </div>

                <div className="form-group col-md-6 col-12">
                  <label for="exampleInputEmail1">Industry Type :</label>
                  <input
                    type="text"
                    name="industryType"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={jobDetail?.industryType}
                    onChange={e => HandleChange(e)}
                    placeholder="Enter full industry type"
                  />
                </div>

                <div className="form-group  col-md-6 col-12">
                  <SelectableInput
                    title="Education "
                    subTitle="Enter one or more Qualification"
                    // value={jobDetail?.education}
                    value={jobDetail?.education}
                    onChange={val => {
                      setJobDetails({
                        ...jobDetail,
                        education: val,
                      });
                    }}
                    className="form-group"
                    placeholder="Enter Education(s) "
                  />
                  {/* <small id="emailHelp" class="form-text text-muted">
                    Press enter after each qualification .
                  </small> */}

                  <span style={{ color: 'red' }}>Press enter after adding each qualification .</span>
                </div>

                <div className="form-group col-md-6 col-12">
                  <div>
                    <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
                      <div>
                        {/* <select
                          class="form-select"
                          aria-label="Default select example"
                          value={Recruiter}
                          onChange={e => {
                            setRecruiter(e?.target?.value);
                          }}
                          style={{ border: '1px solid #000', marginTop: '2em' }}
                        >
                          <option value="">Choose Recruiter...</option>
                          {recruiterData &&
                            recruiterData?.map(ele => {
                              return <option value={ele?._id}>{ele?.fullName}</option>;
                            })}
                        </select> */}

                        <div className="col">
                          <label htmlFor="formGroupExampleInput">Assign to Recruiter</label>

                          <Select
                            className="select"
                            options={recruiterData}
                            value={assignedRecruitersName}
                            name="recruiter"
                            isMulti
                            onChange={HandleRecruiter}
                          />
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: '7rem',
                          }}
                        >
                          <div style={{ margin: '15px' }}>
                            <button className="btn btn-success" onClick={e => AssignToRecruiter(e)}>
                              Done
                            </button>
                          </div>
                          <div style={{ margin: '15px' }}>
                            <button
                              onClick={() => {
                                setRecruiter([]);
                                setAssignedRecruitersName([]);
                                setRecruiterName('');
                                closeModal();
                              }}
                              className="btn btn-danger"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </div>
                {/* Modal 2 */}
                <div className="form-group col-md-6 col-12">
                  <div>
                    <Modal isOpen={modalIsOpen1} onRequestClose={closeModal1} style={customStyles}>
                      <div>
                        {/* <select
                          class="form-select"
                          aria-label="Default select example"
                          value={Recruiter}
                          // onChange={e => {
                          //   setRecruiter(e?.target?.value);
                          // }}
                          style={{ border: '1px solid #000', marginTop: '2em' }}
                        >
                          <option value="">Choose Manager...</option>
                          {managerData &&
                            managerData?.map(ele => {
                              return <option value={ele?._id}>{ele?.fullName}</option>;
                            })}
                        </select> */}

                        <div className="col">
                          <label htmlFor="formGroupExampleInput">Assign to Manager</label>

                          <Select
                            className="select"
                            options={managerData}
                            value={assignedManagersName}
                            name="manager"
                            isMulti
                            onChange={HandleManager}
                          />
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: '7rem',
                          }}
                        >
                          <div style={{ margin: '15px' }}>
                            <button className="btn btn-success" onClick={e => AssignToManager(e)}>
                              Done
                            </button>
                          </div>
                          <div style={{ margin: '15px' }}>
                            <button
                              onClick={() => {
                                SetManager([]);
                                setRecruiterName('');
                                setAssignedManagersName([]);
                                closeModal1();
                              }}
                              className="btn btn-danger"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>

              {hide ? (
                <button className="btn btn-primary" onClick={onsubmit}>
                  Submit
                </button>
              ) : (
                <button className="btn btn-primary" onClick={e => onUpdate(e)}>
                  Update
                </button>
              )}
              <div
                style={{
                  textAlign: 'center',
                  fontSize: '20px',
                  color: '#868e96',
                  margin: '35px',
                }}
                className="card-title"
              >
                View All job List
              </div>
              <DataTable columns={columns} data={AcoountManagerData} pagination />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAndManageJob;
