
import React, { useEffect, useState } from 'react'
import '../RecruiterJobs/JobList.css'
import HttpClient from '../../components/HttpClient';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import DataTable from 'react-data-table-component';
import toast from 'react-hot-toast';


const Loader = () => {
  return (
    <div style={{ position: 'fixed', top: '25%', left: '40%', zIndex: 9999, fontSize: "25px", marginLeft: "20px" }}>
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};
const HrJobsList = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [jobsListData, setJobsListData] = useState([])
  const [jobsStatusData, setJobsStatusData] = useState([])
  const [modal, setModal] = useState(false);
  const [statusData, setStatusData] = useState("");
  const [jobID, setJobID] = useState("")

  // console.log("jobsListData", jobsData);

  const columns = [
    {
      name: (
        <div
        >
          SL
        </div>
      ),
      selector: (row) => row.sl,
      sortable: true,
      center: true,
    },
    {
      name: (
        <div
        >
          Job Title
        </div>
      ),
      selector: (row) => row.jobTitle,
      center: true,
    },
    {
      name: (
        <div
        >
          Location
        </div>
      ),
      selector: (row) => row.jobLocation,
      center: true,
    },
    {
      name: (
        <div
        >
          Account Manager
        </div>
      ),
      selector: (row) => row.accManager,
      center: true,
    },
    {
      name: (
        <div
        >
          CV Uploaded
        </div>
      ),
      selector: (row) => row.totalResumePosted,
      center: true,
    },

    {
      name: (
        <div 
        >
          Add Status
        </div>
      ),
      selector: (row) => row.addstatus,
      center: true,
    },

    {
      name: (
        <div 
        >
          Status
        </div>
      ),
      selector: (row) => row.status,
      center: true,
    },
    
    {
      name: (
        <div
        >
          Action
        </div>
      ),
      selector: (row) => row.action,
      center: true,
    },
  ];

  // >>>>Fetch all job list Data<<<<
  const fetchAllJobsList = async () => {
    let res = await HttpClient.requestData('jobList', 'GET');
    console.log("jobsData", res);

    if (res && res?.status) {
      let arr = res?.data?.reverse()?.map((item, index) => {

        return {
          sl: index + 1,
          id: item?._id,
          jobTitle: item?.jobTitle,
          keySkills: item?.keySkills,
          jobLocation: item?.jobLocation,
          accManager: item?.jobCreatorName,
          totalResumePosted: item?.ResumeData?.length,
          recruiterName: item?.assignedRecruiterName,
          accountManager: item?.assignedAmName,
          dataandtime: item?.createdAt,
          addstatus: (
            <span>
              <button className='btn btn-success' onClick={() => handleStatusModal(item)}>Status</button>
            </span>
          ),
          status: item?.status ? <p style={{ color: "green", fontWeight: "500", fontSize: "14px" }}>{item?.status}</p> : "No Status",
          action: (
            <>
              <div className='right'>
                <button class="btn btn-outline-success eyeicn"
                  title="View CV"
                  onClick={() => {
                    handleView(item?._id);
                  }}
                  style={{
                    marginLeft: "30px",
                    fontSize: '18px',
                  }}><i class="fa-solid fa-eye"></i>
                </button>
                <button className='mx-3' onClick={() => handleUploadCV(item)}>Upload CVs</button>
              </div>
            </>
          )
        };
      });
      setJobsListData(arr);
    }
    setLoading(false);
  };

  // >>>>Fetch all Status data<<<<
  const fetchAllStatus = async () => {
    const res = await HttpClient.requestData('jobStatus', 'GET');
    // if (res && res?.status) {
    setJobsStatusData(res?.data)
    // }
  }

  const handleView = (id) => {
    //  console.log("mnbjhgyugt7657",data)
    navigate("/hr-manager-view-cv", { state: id });
  }
  // >>>>Toggle Add Status Modal<<<<
  const toggleModal = () => {
    setModal(!modal); // Toggle modal state
  };

  const handleStatusModal = (item) => {
    console.log("fjg6fd", item)
    setJobID(item?._id)
    toggleModal()
  }

  const handleUploadCV = (id) => {
    console.log("321mnbjhk678", id)
    navigate("/am-view-job-upload-CV", { state: id })
    // getSingleJobDetails()
  };

  // >>>>Handle Update Status<<<<
  const handleUpdateStatus = async () => {
    if (statusData === "") {
      toast.error("Please select status")
    } else {
      let data = {
        status: statusData
      }
      const res = await HttpClient.requestData(`status/${jobID}`, "PUT", data);
      // if (res && res?.status) {
        console.log("fdgfd5456", res?.data)
        toast.success("Status updated successfully!") 
        fetchAllJobsList()
        setStatusData("")
        window.location.reload();
      // } else {
      //   toast.error("Can't update status!")
      // }
    }
  }

  useEffect(() => {
    fetchAllJobsList();
    fetchAllStatus();
  }, [])
  return (
    <>
      <div
        className=""
        style={{ width: '100%', alignItems: 'center', justifyContent: 'center', margin: '15px 0 20px' }}
      >
        <h2 style={{ fontSize: '26px', fontWeight: '600', textAlign: 'center' }}>Jobs List</h2>
      </div>
      {loading ? ( // Render loader if data is loading
        <Loader />
      ) : jobsListData && jobsListData?.length === 0 ? (
        <p>No data available</p>
      ) : (
        // https://t4.ftcdn.net/jpg/00/66/03/41/360_F_66034158_HQvWJR1D1TtLSxF3ONcRWEI779DLTyIb.jpg
        // <h5>vghvh</h5>
        <>
          <div style={{
            width: '100%',
            padding: '0 20px 20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
          }}>
            <div className="list">
              <DataTable columns={columns} data={jobsListData} pagination />
            </div>
          </div>
        </>
      )}
      {/* Modal for adding status */}
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Status</ModalHeader>
        <ModalBody style={{ margin: "10px" }}>
          {/* Content of the modal */}
          {/* You can add input fields or any other content here */}
          <select
            class="w-100"
            style={{
              border: "1px solid #ccc",
              borderRadius: "5px",
              padding: "10px",
            }}
            onChange={(e) => setStatusData(e.target.value)}
          >
            <option value="">status...</option> 
            {
              jobsStatusData && jobsStatusData?.map((ele) => {
                return (
                  <option value={ele?.status}>{ele?.status}</option>
                )

              })
            }
          </select>
        </ModalBody>

        <ModalFooter>
          <button className='btn'
            style={{
              border: "1px solid #3ac47d",
              color: "#3ac47d",
              fontSize: "15px",
              fontWeight: "bold",
              transition: "background-color 0.3s",
              backgroundColor: "transparent",
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = "#3ac47d";
              e.target.style.color = "#fff";
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = "transparent";
              e.target.style.color = "#3ac47d";
            }}
            onMouseDown={(e) => {
              e.target.style.backgroundColor = "#29754c";
            }}
            onMouseUp={(e) => {
              e.target.style.backgroundColor = "#3ac47d";
            }}
            onClick={handleUpdateStatus}
          >
            Add Status
          </button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default HrJobsList
