import React, { useEffect, useState } from 'react'
import '../RecruiterJobs/JobList.css'
import HttpClient from '../../components/HttpClient';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const AmJobsList = () => {


  const navigate = useNavigate();
  const [jobsListData, setJobsListData] = useState([])
  const [jobsData, setJobsData] = useState([])
  const [modal, setModal] = useState(false);
  const [singleData, setSingleData] = useState({});


  // console.log("jobsListData", jobsData);

  const fetchAllJobsList = async () => {
    let res = await HttpClient.requestData('jobList', 'GET');
    console.log("jobsData", res);
    setJobsData(res)

    if (res && res?.status) {
      let arr = res?.data?.map((item, index) => {

        return {
          sl: index + 1,
          id: item?._id,
          jobTitle: item?.jobTitle,
          keySkills: item?.keySkills,
          jobLocation: item?.jobLocation,
          totalResumePosted: item?.ResumeData?.length,
          recruiterName: item?.assignedRecruiterName,
          accountManager: item?.assignedAmName,
          dataandtime: item?.createdAt,
        };
      });
      setJobsListData(arr);
    }
  };

  const handleView = (id) => {
    //  console.log("mnbjhgyugt7657",data)
    navigate("/hr-manager-view-cv", { state: id });
  }

  const handleViews = id => {
    console.log("321mnbjhk678", id)
    navigate('/hr-view-job-upload-CV', { state: id });
    // getSingleJobDetails()
  };

  useEffect(() => {
    fetchAllJobsList()
  }, [])
  return (
    <>
      <div
        className=""
        style={{ width: '100%', alignItems: 'center', justifyContent: 'center', margin: '15px 0 20px' }}
      >
        <h2 style={{ fontSize: '26px', fontWeight: '600', textAlign: 'center' }}>Jobs List</h2>
      </div>
      {jobsListData && jobsListData?.length === 0 ? (
        <img
          style={{ position: 'fixed', top: '25%', left: '40%' }}
          src="https://t4.ftcdn.net/jpg/00/66/03/41/360_F_66034158_HQvWJR1D1TtLSxF3ONcRWEI779DLTyIb.jpg"
        />
      ) : (
        // https://t4.ftcdn.net/jpg/00/66/03/41/360_F_66034158_HQvWJR1D1TtLSxF3ONcRWEI779DLTyIb.jpg
        <div
          className=""
          style={{
            width: '100%',
            padding: '0 20px 20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
          }}
        >
          {jobsListData?.map((item, index) => {
            console.log('jd', item);
            return (
              <ul className="parentlist" key={index}>
                <div className="list">
                  <div className="left mb-2">
                    <h3 style={{ fontSize: '15px', fontWeight: '600', color: '#000' }}>
                      JobTitle:{' '}
                      <span style={{ marginLeft: '6px', fontWeight: '500', color: 'rgb(92 89 89)' }}>
                        {item?.jobTitle}
                      </span>
                    </h3>
                    <h4 style={{ fontSize: '13px', fontWeight: '600', color: '#000' }}>
                      KeySkill:
                      <span style={{ marginLeft: '6px', fontWeight: '400', color: 'rgb(92 89 89)' }}>
                        {item?.keySkills?.join(',')}
                      </span>
                    </h4>
                    <h4 style={{ fontSize: '12px', fontWeight: '600', color: '#000' }}>
                      Location:
                      <span style={{ marginLeft: '6px', fontWeight: '400', color: 'rgb(92 89 89)' }}>
                        {item?.jobLocation?.join(',')}
                      </span>
                    </h4>
                    {item?.recruiterName && (
                      <h4 style={{ fontSize: '12px', fontWeight: '600', color: '#000' }}>
                        Recruiter :
                        <span style={{ marginLeft: '6px', fontWeight: '400', color: 'rgb(92 89 89)' }}>
                          {item?.recruiterName?.join(',')}
                        </span>
                      </h4>
                    )}
                    {item?.accountManager && (
                      <h4 style={{ fontSize: '12px', fontWeight: '600', color: '#000' }}>
                        Account Manager :
                        <span style={{ marginLeft: '6px', fontWeight: '400', color: 'rgb(92 89 89)' }}>
                          {item?.accountManager?.join(',')}
                        </span>
                      </h4>
                    )}
                    {item?.dataandtime && (
                      <h4 style={{ fontSize: '12px', fontWeight: '600', color: '#000' }}>
                        Date & time :
                        <span style={{ marginLeft: '6px', fontWeight: '400', color: 'rgb(92 89 89)' }}>
                          {new Date(item?.dataandtime).getUTCFullYear()}-
                          {new Date(item?.dataandtime).getUTCMonth() + 1}-{new Date(item?.dataandtime).getUTCDate()} &{' '}
                          {new Date(item?.dataandtime).getUTCHours()} : {new Date(item?.dataandtime).getUTCMinutes()}
                        </span>
                      </h4>
                    )}
                    <h4 style={{ fontSize: '16px', fontWeight: '700', color: '#000' }}>
                      Total Cv:
                      <span style={{ fontSize: '16px', marginLeft: '6px', fontWeight: '400', color: '#817d7d' }}>
                        {item?.totalResumePosted ? (
                          item?.totalResumePosted
                        ) : (
                          <div style={{ color: 'red' }}>No cv uploaded</div>
                        )}
                      </span>
                    </h4>
                  </div>
                  <div className="right">
                    <button onClick={() => handleViews(item)}>Upload CVs</button>
                    <button class="btn btn-outline-success eyeicn"
                      title="View CV"
                      // onClick={() => {
                      //   setModal(true); 
                      //   viewCVdata(item)
                      // }}
                      onClick={() => {
                        handleView(item?.id);
                        setModal(true);
                      }}
                      style={{
                        // fontWeight: 'bold',
                        marginLeft: "30px",
                        fontSize: '18px',
                      }}><i class="fa-solid fa-eye"></i>
                    </button>
                    {/* <button onClick={() => handleView(item?.id)}>View Jobs And View CVs</button> */}
                  </div>
                  {/* <div className="right">
                  <button onClick={() => handleViews(item)}>Upload CVs</button>
                </div> */}
                </div>
              </ul>
            );
          })}
        </div>
      )}
    </>
  )
}

export default AmJobsList