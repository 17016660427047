import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Viewjobs.css";
import { CSVLink } from "react-csv";
import HttpClient from "../../components/HttpClient";
import { useLocation } from "react-router-dom";
import { useScrollTrigger } from "@mui/material";
import toast from "react-hot-toast";
import { IoMdDownload } from 'react-icons/io';
import DocViewer from "react-doc-viewer";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useStateContext } from "../../contexts/ContextProvider";
import moment from "moment";
import { DateRangePicker } from "react-date-range";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Multiselect from "multiselect-react-dropdown";
import DataTable from "react-data-table-component";
import SelectableInput from "../Job/SelectableInput";



const Loader = () => {
  return (
    <div style={{ position: 'fixed', top: '25%', left: '40%', zIndex: 9999, fontSize: "25px", marginLeft: "20px" }}>
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

const recruitData = {
  name: "",
  email: "",
  experience: "",
  phoneNo: "",
  skills: [],
  location: "",
};

const ViewAllReports = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [apiEnd, setApiEnd] = useState({});
  const [recruiterData, setRecruiterdata] = useState(recruitData);
  const [serialNumber, setSerialNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [cvData, setCvData] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [searchText, setSearchText] = useState("");
  const [jobSearchText, setJobSearchText] = useState("");
  const [hrId, setHrId] = useState()
  const [openModal, setOpenModal] = useState(false)
  const [CvIndData, setCvIndData] = useState([])
  const [jobName, setJobName] = useState("");
  console.log("fsdfsdfsdfdddddd", cvData);
  const [jobsListData, setJobsListData] = useState([])
  const [filteredJobsList, setFilteredJobsList] = useState([]);
  const [isShowPicker, setIsShowPicker] = useState(false);
  const [allRecruiter, setAllRecruiter] = useState([])
  const [selectedRecruiters, setSelectedRecruiters] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [AllJob, setAllJob] = useState([]);
  const [recData, setRecData] = useState("");
  const [singleJob, setSingleJOb] = useState([])
  const [newFilteByDate, setNewFilteByDate] = useState([])
  const [modal, setModal] = useState(false)

  // const [allRecruiter, setRecruiter] = useState([]);

  console.log("jobsListData46", jobsListData);


  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const columns = [
    {
      name: (
        <div

        >
          SL
        </div>
      ),
      selector: (row) => row.sl,
      // sortable: true,
    },
    {
      name: (
        <div

        >
          Name
        </div>
      ),
      selector: (row) => row.name,
    },
    {
      name: (
        <div

        >
          {" "}
          Email
        </div>
      ),
      selector: (row) => row.email,
    },
    {
      name: (
        <div

        >
          PhoneNo
        </div>
      ),
      selector: (row) => row.phoneNo,
    },
    {
      name: (
        <div

        >
          Recruiter
        </div>
      ),
      selector: (row) => row.recName,
    },
    {
      name: (
        <div

        >
          Date
        </div>
      ),
      selector: (row) => row.date,
    },
    {
      name: (
        <div

        >
          Action
        </div>
      ),
      selector: (row) => row.action,
    },
  ];

  const fetchAllJob = async () => {
    const res = await HttpClient.requestData('jobList', 'GET')
    if (res && res?.status) {

      setAllJob(res?.data)

    }
    console.log("allrecruiter54354", res?.data)
  }

  const { userProfileData } = useStateContext();

  console.log("mndfbgjhg98", selectedDate)
  // useEffect(() => {
  //  userProfileData && FetchAllCv(userProfileData?._id);
  // }, [userProfileData]);

  const FetchAllCv = async () => {
    let res = await HttpClient.requestData('fetch-resume', 'GET');
    console.log("mnbhyu67", res?.data)
    if (res && res?.status) {
      let arr = res?.data?.map((ele, ind) => {
        return {
          id: ele?._id,
          jobID: ele?.jobId,
          sl: ind + 1,
          name: ele?.name,
          email: ele?.email,
          phoneNo: ele?.phoneNo,
          recName: ele?.userName,
          date: moment(ele?.createdOn)?.format('DD-MM-YYYY'),
          action: (
            <>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                <div>
                  <Link to={ele?.resume} title="Download CV"><i class="fa-solid fa-download"></i></Link>
                </div>

                <div>
                  <button
                    title="View CV"
                    onClick={() => {
                      handleViewData(ele)
                    }}
                  // style={{
                  //   marginLeft: "30px",
                  //   fontSize: '18px',
                  // }}
                  ><i class="fa-solid fa-eye"></i>
                  </button>
                </div>
              </div>
            </>
          )
        }
      })
      console.log("mxnbgyj5675r7", arr)
      setCvData(arr)
      setJobsListData(arr)
      setFilteredJobsList(arr)
      setSerialNumber(1)
      // setCvData(res?.data)
      // setJobsListData(res?.data)
      // setFilteredJobsList(res?.data)
      setLoading(false);

      const recruiter = Array.from(new Set(res?.data.flatMap(item => item?.userName || [])))?.filter(rec => rec?.trim() !== '')
      console.log("xmncvsjkdfhweuiry43875y", recruiter)
      setAllRecruiter(recruiter)
    }
  };

  const handleViewData = (ele) => {

    navigate('/view-job', { state: ele })
  }

  const handleSelectRecruiter = (selectedList, selectedItem) => {
    setSelectedRecruiters(selectedList);
    const fltData = jobsListData?.filter(item => {
      const obj = selectedList.find(ele => ele?.fullName === item?.userName);
      if (obj) {
        return true;
      } else {
        return false;
      }
    });
    setFilteredJobsList(fltData);
    console.log("ft456fgfx546", fltData);
  };

  const handleRemoveRecruiter = (selectedList, removedItem) => {
    setSelectedRecruiters(selectedList);
  };

  useEffect(() => {
    FetchAllCv()
    // fetchAllRecruiter();
    fetchAllJob()
  }, [])


  const handleSearch = () => {
    let filteredJobsByDate = cvData;
    let newFilData = []
    if (singleJob?.length > 0 && newFilteByDate?.length > 0) {
      if (dateRange[0]) {
        const startDate = moment(dateRange[0].startDate);
        const endDate = moment(dateRange[0].endDate);
        newFilData = newFilteByDate.filter(job => {
          const jobDate = moment(job.date, 'DD-MM-YYYY');
          return jobDate.isSameOrAfter(startDate, 'day') &&
            jobDate.isSameOrBefore(endDate, 'day');
        });
      }

      return setFilteredJobsList(newFilData)
    }

    if (dateRange[0]) {
      const startDate = moment(dateRange[0].startDate);
      const endDate = moment(dateRange[0].endDate);
      filteredJobsByDate = filteredJobsByDate.filter(job => {
        const jobDate = moment(job.date, 'DD-MM-YYYY');
        return jobDate.isSameOrAfter(startDate, 'day') &&
          jobDate.isSameOrBefore(endDate, 'day');
      });
    }
    setFilteredJobsList(filteredJobsByDate);
    console.log("Filtered_Jobs:", filteredJobsByDate);

  };


  const handleClear = () => {
    setApiEnd({});
    setFilteredJobsList(cvData)
    setSelectedDate()
    setJobSearchText('')
    setSearchText("");
    setRecData('');
    setDateRange([
      { startDate: new Date(), endDate: new Date(), key: 'selection' }
    ]);
  };

  const JobWiseResumeChecking = (id) => {
    console.log("fjkdnguire67843g", id, cvData)
    setJobSearchText(id)
    if (id == "") {
      FetchAllCv()
    }
    const filterData = cvData?.filter((ele) => ele?.jobID === id);
    if (filterData) {
      setFilteredJobsList(filterData);
      setSingleJOb(filterData);
    } else {
      setFilteredJobsList([])
    }

    console.log("JObID", id, cvData, filterData);
  }

  const RecruiterWiseResumeChecking = (id) => {
    setRecData(id)
    if (singleJob?.length > 0) {
      let filterJobData = singleJob?.filter((ele) => ele?.recName === id);
      setNewFilteByDate(filterJobData)
      return setFilteredJobsList(filterJobData)
    }

    if (id == "") {
      FetchAllCv();
    }
    let filterByAM = jobsListData
    const recruiterNameData = filterByAM?.filter(item => {
      if (item?.recName) {
        const newData = item?.recName === id;
        return newData
      }
    })
    setFilteredJobsList(recruiterNameData)
  }


  const handleInputClick = () => {
    setIsShowPicker(prev => !prev);
    setSelectedDate(cvData[0]?.createdOn);
  };

  const prepareDataForCSV = () => {
    if (filteredJobsList.length > 0) {
      return filteredJobsList.map((row) => ({
        Name: row?.name,
        Email: row?.email,
        Phone: row?.phoneNo,
        Recruiter: row.recName,
        Date: row?.date,
      }));
    } else {
      return jobsListData.map((row) => ({
        Name: row?.name,
        Email: row?.email,
        Phone: row?.phoneNo,
        Recruiter: row?.recName,
        Date: row?.date,
      }));
    }
  };

  // >>>>Handle Toogle Modal<<<<
  const toggleModal = () => {
    setModal(!modal);
  }

  // >>>>Handle The Advance Filter Input<<<<
  const HandleChange = (e) => {
    setRecruiterdata({
      ...recruiterData,
      [e.target.name]: e.target.value,
    });
  };

  // >>>>Handle API end<<<<
  const handleApiEnd = (filterValue, filterKey) => {
    console.log("gh4fg6", filterValue, filterKey)


    setApiEnd(prev => ({
      ...prev,
      [filterKey]: {
        key: filterKey,
        value: filterValue
      }
    }))
  }

  // >>>>HAndle Advance Filter<<<<
  const handleAdvanceFilter = async () => {
    const endArr = Object.values(apiEnd)
    console.log("rg54r1f", endArr)
    const queryParams = endArr.map(({ key, value }) => `${key}=${encodeURIComponent(value)}`).join('&');
    const res = await HttpClient.requestData("resumeFilter?" + queryParams, "GET");
    if (res && res?.status) {
      let arr = res?.data?.map((ele, ind) => {
        return {
          id: ele?._id,
          jobID: ele?.jobId,
          sl: ind + 1,
          name: ele?.name,
          email: ele?.email,
          phoneNo: ele?.phoneNo,
          recName: ele?.userName,
          date: moment(ele?.createdOn)?.format('DD-MM-YYYY'),
          action: (
            <>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                <div>
                  <Link to={ele?.resume} title="Download CV"><i class="fa-solid fa-download"></i></Link>
                </div>

                <div>
                  <button
                    title="View CV"
                    onClick={() => {
                      handleViewData(ele)
                    }}
                  ><i class="fa-solid fa-eye"></i>
                  </button>
                </div>
              </div>
            </>
          )
        }
      })
      setFilteredJobsList(arr)
      setModal(false)
      setRecruiterdata(recruitData)
      setApiEnd({});
    } else {
      toast.error(res?.message)
      setModal(false)
      setRecruiterdata(recruitData)
      setApiEnd({});
    }
  }

  return (
    <div onClick={() => setIsShowPicker(false)}>
      <h1 className="my-3">View CV List</h1>

      <div className="filterDiv">
        <div style={{ width: '25%' }}>

          <select style={{ width: "100%", height: "44px" }} value={jobSearchText} onChange={(e) => { JobWiseResumeChecking(e.target.value); setJobName(e.target.value) }} >
            <option value="">Search by Job title...</option>
            {
              AllJob?.map((ele, index) => {
                return (
                  <option value={ele?._id} key={index}>{ele?.jobTitle}</option>
                )
              })
            }

          </select>

        </div>
        <div style={{ width: '25%' }}>
          <select style={{ width: "100%", height: "44px" }} onChange={(e) => { RecruiterWiseResumeChecking(e.target.value) }} value={recData} >
            <option value="">Search by Recruiter...</option>
            {
              allRecruiter?.map((ele, index) => {
                return (
                  <option value={ele} key={index}>{ele}</option>
                )
              })
            }

          </select>
        </div>

        <div className="datePicker calenderpacakgediv" style={{ width: '25%' }}>
          <button
            style={{
              backgroundColor: "white",
              color: "black",
              border: "1px solid #999",
              // margin: "19px",
              width: "15rem"
            }}
            onClick={(e) => {
              e.stopPropagation()
              handleInputClick()
            }}
            placeholder="Select date..."
            readOnly
          > {selectedDate ? moment(selectedDate?.[0]?.startDate).format('DD-MM-YYYY') : "Start date"} - {selectedDate ? moment(selectedDate?.[0]?.endDate).format('DD-MM-YYYY') : "End date"}
          </button>


        </div>

        <div style={{ display: 'flex', gap: '10px' }} >
          <button
            className="btn btn-outline-success"
            onClick={() => {
              // handleDateSearch();
              handleSearch();
              setIsShowPicker(false)
            }}
          >
            Search
          </button>

          <button
            className="btn btn-outline-success"
            onClick={() => {
              handleClear();
              setIsShowPicker(false)
            }}
          >
            Clear filter
          </button>

          <button
            className="btn btn-outline-success"
            onClick={() => {
              toggleModal(); 
            }}
          >
            Advancs Filter
          </button>

          <CSVLink
            className="btn btn-outline-success"
            style={{
              fontSize: "20px",
              height: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"

            }}
            data={prepareDataForCSV()}
            filename={"report.csv"}
            title="Download file"
          >
            <i class="fa-regular fa-circle-down"></i>
          </CSVLink>


        </div>

      </div>
      <div className="datepickerdivcalender" onClick={(e) => e.stopPropagation()}>
        {isShowPicker && (
          <DateRangePicker
            onChange={(ranges) => {
              setDateRange([ranges.selection]);
              setSelectedDate([ranges.selection])
              setIsShowPicker(false)
            }}
            showDateDisplay={false}
            moveRangeOnFirstSelection={false}
            ranges={dateRange}
          />
        )}
      </div>

      {
        loading ? (
          <Loader />
        )
          : (
            <div className="card-body">
              <DataTable
                columns={columns}
                data={filteredJobsList}
                pagination
              />
            </div>
          )
      }

      {/*Advance Filter Modal */}
      
      <div>
        <Modal isOpen={modal} toggle={toggleModal} size='xl'>
          <ModalHeader toggle={toggleModal} >
            <h1 style={{ color: "#000", fontWeight: "500" }}>Advance Filter</h1>
          </ModalHeader>
          <ModalBody>
            <div>
              <div className="row" style={{ padding: '20px' }}>
                <div className="form-group col-md-6 col-12">
                  <label for="exampleInputEmail1">
                    Name<span style={{ color: 'red' }}> *</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={recruiterData?.name}
                    onChange={(e) => {
                      HandleChange(e)
                      handleApiEnd(e.target.value, "name")
                    }}
                    placeholder="Enter full Name"
                    style={{ height: '2em', fontSize: '20px' }}
                  />
                </div>
                <div className="form-group col-md-6 col-12">
                  <label for="exampleInputEmail1">
                    Email<span style={{ color: 'red' }}> *</span>
                  </label>
                  <input
                    type="text"
                    name="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={recruiterData?.email}
                    onChange={(e) => {
                      HandleChange(e)
                      handleApiEnd(e.target.value, "email")
                    }
                    }
                    placeholder="Enter your Email"
                    style={{ height: '2em', fontSize: '20px' }}
                  />
                </div>
                <div className="form-group col-md-6 col-12">
                  <label for="exampleInputEmail1">
                    Experience<span style={{ color: 'red' }}> *</span>
                  </label>
                  <input
                    type="text" 
                    oninput="validity.valid||(value='');"
                    name="experience"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={recruiterData?.experience}
                    placeholder="Enter your Experience..."
                    style={{ height: '2em', fontSize: '20px' }}
                    onChange={e => {
                      setRecruiterdata({
                        ...recruiterData,
                        experience: e.target.value,
                      });
                      handleApiEnd(e.target.value, "experience")
                    }}
                  />
                </div>
                <div className="form-group col-md-6 col-12">
                  <label for="exampleInputEmail1">
                    Phone No.<span style={{ color: 'red' }}> *</span>
                  </label>
                  <input
                    type="number"
                    name="phoneNo"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={recruiterData?.phoneNo}
                    onChange={(e) => {
                      HandleChange(e)
                      handleApiEnd(e.target.value, "phoneNo")
                    }
                    }
                    placeholder="Enter your Phone No..."
                    style={{ height: '2em', fontSize: '20px' }}
                  />
                </div>

                <div className="form-group col-12" style={{}}>
                  <label for="exampleInputEmail1">
                    Skills<span style={{ color: 'red' }}> *</span>
                  </label>
                  <input
                    type="text"
                    name="skills"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={recruiterData?.skills}
                    onChange={(e) => {
                      HandleChange(e)
                      handleApiEnd(e.target.value, "skills")
                    }
                    }
                    placeholder="Enter your Location..."
                    style={{ height: '2em', fontSize: '20px' }}
                  />
                </div>

                <div className="form-group col-12" style={{}}>
                  <label for="exampleInputEmail1">
                    Location<span style={{ color: 'red' }}> *</span>
                  </label>
                  <input
                    type="text"
                    name="location"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={recruiterData?.location}
                    onChange={(e) => {
                      HandleChange(e)
                      handleApiEnd(e.target.value, "location")
                    }
                    }
                    placeholder="Enter your Location..."
                    style={{ height: '2em', fontSize: '20px' }}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
 
 
          <div className="d-flex justify-content-evenly mb-2">
            <div>
              <button className='btn btn-success'
                style={
                  {
                    width: "30em"
                  }
                }
                onClick={handleAdvanceFilter}
              >Search</button>
            </div>

            <div>
              <button className='btn btn-danger'
                style={
                  {
                    width: "30em"
                  }
                }
                onClick={toggleModal}
              >Cancel</button>
            </div> 
          </div> 

        </Modal>
      </div>

      {/*Advance Filter Modal */}




      {/* {show && docFile && (<iframe src={docFile} width="800" height="600" style="border: 1px solid #ccc;"></iframe>)} */}
    </div>
  );
};

export default ViewAllReports