import React, { useEffect, useState } from 'react'
import './Upload.css'
import HttpClient from '../../components/HttpClient';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const UploadCVHR = () => {
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const [jobsListData, setJobsListData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [singleData, setSingleData] = useState({});
    const [countCV, setCountCV] = useState([]);

    const { userProfileData, setRecruiterAssignJob } = useStateContext();

    // console.log('userProfileData', singleData);  

    const fetchAllJobsList = async (id) => {
        // alert("hii")
        let res = await HttpClient.requestData('jobList', 'GET');
        console.log('RESPONSEfsfdgfgdfgdfgdfgdfgdfg', res?.data);
        let newArr = [];

        res?.data?.forEach((ele) => {

            ele?.assignedRecruiter?.forEach((element) => {
                if (element === id) {
                    newArr.push(ele);
                }
            })
        })

        newArr && setRecruiterAssignJob(newArr?.length);
        if (res && res?.status) {

            let arr = newArr && newArr?.map((item, index) => {
                return {
                    sl: index + 1,
                    id: item?._id,
                    jobTitle: item?.jobTitle,
                    keySkills: item?.keySkills,
                    jobLocation: item?.jobLocation,
                    totalResumePosted: item?.ResumeData?.length,
                    assignRecruiterName: item?.assignedRecruiterName,
                    resumeId: item?.ResumeData[0]?._id,
                    dataandtime: item?.createdAt,
                };
            });
            setJobsListData(arr);
        }
    };

    const handleView = id => {
        console.log("321mnbjhk678", id)
        navigate('/view-job-upload-CV', { state: id });
        // getSingleJobDetails()
    };

    const viewCVdata = (data) => {
        getSingleJobDetails(data?.id)
    }

    const getSingleJobDetails = async (id) => {
        setLoading(true);
        const res = await HttpClient.requestData(`job/${id}`, "GET");
        console.log("RESFDSFFDSFSF", res);
        if (res && res?.status) {
            // console.log("res");
            setSingleData(res?.data[0]);
            // res?.data[0]?.ResumesData[0] &&
            //   setUploadedCV(res?.data[0]?.ResumesData[0]?.resume);
            res?.data[0]?.ResumesData[0] &&
                setCountCV(res?.data[0]?.ResumesData[0]?.resume);

            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (userProfileData) fetchAllJobsList(userProfileData?._id);
    }, [userProfileData]);


    return (

        <div
            className=""
            style={{
                width: '100%',
                padding: '20px 20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
            }}
        >
            <h1>Vivek</h1>
            {jobsListData?.map(item => {
                // console.log('ITEMS', item?.ResumeData[0]);
                return (
                    <ul className="parentlist">
                        <div className="list">
                            <div className="left mb-2">
                                <h3 style={{ fontSize: '15px', fontWeight: '600', color: '#000' }}>
                                    JobTitle:{' '}
                                    <span style={{ marginLeft: '6px', fontWeight: '500', color: 'rgb(92 89 89)' }}>{item?.jobTitle}</span>
                                </h3>
                                <h4 style={{ fontSize: '13px', fontWeight: '600', color: '#000' }}>
                                    KeySkill:
                                    <span style={{ marginLeft: '6px', fontWeight: '400', color: 'rgb(92 89 89)' }}>
                                        {item?.keySkills?.join(',')}
                                    </span>
                                </h4>
                                <h4 style={{ fontSize: '12px', fontWeight: '600', color: '#000' }}>
                                    Location:
                                    <span style={{ marginLeft: '6px', fontWeight: '400', color: 'rgb(92 89 89)' }}>
                                        {item?.jobLocation?.join(', ')}
                                    </span>
                                </h4>

                                {/* {item?.assignRecruiterName && ( */}
                                <h4 style={{ fontSize: '12px', fontWeight: '600', color: '#000' }}>
                                    Recruiter :
                                    <span style={{ marginLeft: '6px', fontWeight: '400', color: 'rgb(92 89 89)' }}>
                                        {userProfileData?.fullName}
                                    </span>
                                </h4>
                                {/* )} */}

                                {item?.dataandtime && (
                                    <h4 style={{ fontSize: '12px', fontWeight: '600', color: '#000' }}>
                                        Date & time :
                                        <span style={{ marginLeft: '6px', fontWeight: '400', color: 'rgb(92 89 89)' }}>
                                            {new Date(item?.dataandtime).getUTCFullYear()}-{new Date(item?.dataandtime).getUTCMonth() + 1}-
                                            {new Date(item?.dataandtime).getUTCDate()} & {new Date(item?.dataandtime).getUTCHours()} :{' '}
                                            {new Date(item?.dataandtime).getUTCMinutes()}
                                        </span>
                                    </h4>
                                )}

                                <h4 style={{ fontSize: '16px', fontWeight: '700', color: '#000' }}>
                                    Total Cv:
                                    <span style={{ fontSize: '16px', marginLeft: '6px', fontWeight: '400', color: '#817d7d' }}>
                                        {item?.totalResumePosted ? (
                                            item?.totalResumePosted
                                        ) : (
                                            <div style={{ color: 'red' }}>No cv uploaded</div>
                                        )}
                                    </span>
                                </h4>
                            </div>

                            <div className="right">
                                <button onClick={() => handleView(item)}>Upload CV</button>
                                <button class="btn btn-outline-success"
                                    title="View CV"
                                    onClick={() => {
                                        setModal(true);
                                        viewCVdata(item)
                                    }}
                                    style={{
                                        // fontWeight: 'bold',
                                        marginLeft: "30px",
                                        fontSize: '18px',
                                    }}><i class="fa-solid fa-eye"></i>
                                </button>
                            </div>


                        </div>
                    </ul>
                );
            })}

            <div>
                <Modal size="lg"
                    isOpen={modal}
                    toggle={() => setModal(!modal)}
                >
                    <ModalHeader toggle={() => setModal(!modal)}>
                    </ModalHeader>
                    <ModalBody>
                        <div className="Main">
                            <div className="leftsection">
                                <div className="topleft">
                                    <h5>
                                        <span>Job Title :</span> {singleData?.jobTitle}
                                    </h5>
                                    {singleData?.designation && (
                                        <h5>
                                            <span>Designation :</span> {singleData?.designation}
                                        </h5>
                                    )}
                                    <h5>
                                        <span>Employment Type :</span> {singleData?.employmentType}
                                    </h5>
                                    <h5>
                                        <span>Client :</span> {singleData?.department}
                                    </h5>
                                    <h5>
                                        <span>Key Skills:</span> {singleData?.keySkills?.join(',')}
                                    </h5>
                                    {singleData?.roleCategory && (
                                        <h5>
                                            <span>Role Category :</span> {singleData?.roleCategory}
                                        </h5>
                                    )}
                                    <h5>
                                        <span>Role:</span> {singleData?.role}
                                    </h5>
                                </div>
                                <div className="topright">
                                    <h5>
                                        <span>Work Mode:</span> {singleData?.workMode}
                                    </h5>
                                    <h5>
                                        <span>Job Location :</span> {singleData?.jobLocation?.join(', ')}
                                    </h5>
                                    <h5>
                                        <span>
                                            Experience{' '}

                                            :
                                        </span>{' '}
                                        {singleData?.experience}
                                    </h5> 
                                    <h5>
                                        <span>
                                            Salary{' '}
                                            <span
                                                style={{
                                                    fontSize: '80%',
                                                    fontWeight: 'normal',
                                                }}
                                            >
                                                ( per Annum )
                                            </span>
                                            :
                                        </span>{' '}
                                        {singleData?.salary}
                                    </h5>
                                </div>
                            </div>
                            <div className="rightsection">
                                <div className="left_leftsde">
                                    {singleData?.openings && (
                                        <h5>
                                            <span>Openings:</span> {singleData?.openings}
                                        </h5>
                                    )} 
                                    <h5>
                                        <span>Description:</span> {singleData?.description}
                                    </h5>
                                    {singleData?.industryType && (
                                        <h5>
                                            <span>IndustryType:</span> {singleData?.industryType}
                                        </h5>
                                    )}
                                    {singleData?.education?.length > 0 && (
                                        <h5>
                                            <span>Education:</span> {`${singleData?.education},`}
                                        </h5>
                                    )}
                                    <h5>
                                        <span>JobCreaterType:</span> {singleData?.jobCreaterType}
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        </div>
    )
}

export default UploadCVHR