import React, { useMemo, useState } from "react";
import HttpClient from "../components/HttpClient";
import "../pages/Login.css";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
const Login = () => {
  const [userEmail, setUserEmail] = useState("");
  const [type , setType] = useState('');
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passError, setPassError] = useState("");
  const [typeError , serTypeErr] = useState("");

  const navigate = useNavigate();

  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const emailValidation = () => {
    if (userEmail === "") {
      setEmailError("Email Field cannot be blank.");
    } else if (userEmail != "" && !userEmail.match(mailformat)) {
      setEmailError("Please enter valid email");
    } else {
      setEmailError("");
    }
  };

  const passwordValidation = () => {
    if (password === "") {
      setPassError("Password Field Cannot be blank");
    } else if (password != "" && password.length < 6) {
      setPassError("Password length must be 6 characters or more");
    } else {
      setPassError("");
    }
  };

  // const typeValidation = () => {
  //   if (type === '') {
  //     serTypeErr('User type Field Cannot be blank');
  //   } else {
  //     serTypeErr('');
  //   }
  // }

  const validation = useMemo(() => {
    if (
      userEmail != '' &&
      password != '' &&
      userEmail.match(mailformat) &&
      password.length >= 6 &&
      emailError == '' &&
      passError == ''
    ) {
      return true;
    } else {
      return false;
    }
  }, [userEmail, password, emailError, passError]);

  const onsubmit = async (e) => {
    e.preventDefault();

    let data = {
      email: userEmail,
      password: password,
    };

    if (validation) {
      let result = await HttpClient.requestData("login", "POST", data);
      if (result && result?.status) {
        // alert("")
        toast.success('Login Successfully');
        let auth = {
          loginStatus: true,
          token: result.data.token,
          _id: result.data._id,
        };
        
        reactLocalStorage.setObject('recruiterData', auth);
        // localStorage.setItem('_id',result.data._id);
        reactLocalStorage.set('loginStatus', true);
        setUserEmail('');
        setPassword('');
        window.location.href = '/';
      } else {
        console.log('RESULT', result?.message);
        toast.error(result.message);
      }
    } else {
      emailValidation();
      passwordValidation();
      // typeValidation();
    }
  };


    //   padding: 12px;
    // border: none;
    // border-radius: 5px;
    // margin-bottom: 12px;
    // font-size: 16px;
    // color: #fff;
    // background-color: #555;

  return (
    <div className="container fade-in">
      <div class="form-container" id="login-form">
        <h1 style={{ color: 'rgb(3, 201, 215)' }}>Login Credentials</h1>
        <form className="loginForm">
          {/* <label for="username" style={{ fontWeight: '600' }}>
            User Type
          </label>

          <select
            style={{ marginBottom: '12px', padding: '12px', backgroundColor: '#555', color: '#fff' }}
            class="form-select"
            aria-label="select category"
            value={type}
            onChange={e => setType(e?.target?.value)}
          >
            <option value={''}>Select user type.......</option>

            <option id="HR" value="HR">
              HR
            </option>
            <option id="Account Manager" value="Account Manager">
              Account Manager
            </option>
            <option id="Recuter" value="Recuter">
              Recuter
            </option>
          </select>
          {typeError && <p className="error-login fade-in">{typeError}</p>} */}

          <label for="username" style={{ fontWeight: '600' }}>
            Useremail
          </label>
          <input
            className={`${emailError ? 'error-input' : ''}`}
            type="text"
            id="username"
            name="username"
            placeholder="Enter your email..."
            value={userEmail}
            onChange={e => setUserEmail(e.target.value)}
          />
          {emailError && <p className="error-login fade-in">{emailError}</p>}
          <label for="password" style={{ fontWeight: '600' }}>
            Password
          </label>
          <input
            className={`${passError ? 'error-input' : ''}`}
            type="password"
            id="password"
            placeholder="Enter your password..."
            name="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          {passError && <p className="error-login fade-in">{passError}</p>}
          <button onClick={onsubmit} className="btn-login">
            LOGIN
          </button>
        </form>
       {/* <div class="text-center text-lg-start mt-4 pt-2">
          <p class="large fw-bold mt-2 pt-0 mb-0">
            Don't have an account ?{' '}
            <a href="/register" style={{ color: 'rgb(3, 201, 215)' }}>
              Register
            </a>
          </p>
        </div>  */}
      </div>
    </div>
  );
};

export default Login;


