import React, { useEffect } from 'react';
import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useLocation } from 'react-router-dom';
import './App.css';
import { Navbar, Footer, Sidebar, ThemeSettings } from './components';
import AddManager from './pages/Manager/AddManager';
import AddAndManageJob from './pages/Job/AddAndManageJob';
import Modal from 'react-modal';
import { FaEyeSlash } from 'react-icons/fa';
import { FaEye } from 'react-icons/fa';

import { useNavigate } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';

import { Ecommerce } from './pages';
import { useStateContext } from './contexts/ContextProvider';
import JobList from './pages/RecruiterJobs/JobList';
import ViewJobs from './pages/RecruiterJobs/ViewJobs';
import JobsList from './pages/HrManagerJobs/HrJobsList';
import ViewJob from './pages/HrManagerJobs/ViewJob';
import ViewJobList from './pages/HrManagerJobs/ViewJobList';
import AddAndManageManagerJob from './pages/AccountManager/Job/AddAndManageManagerJob';
import ViewAllManagerJob from './pages/AccountManager/Job/ViewAllManagerJob';
import AddAndManageRecruiter from './pages/AccountManager/Recruiter/AddAndManageRecruiter';
import AddRecruiter from './pages/Recruiter/AddRecruiter';
import HttpClient from './components/HttpClient';
import SearchkeySkill from './pages/SearchSkills/SearchkeySkill';
import ViewAllReports from './pages/HrManagerJobs/ViewAllReports';
import toast from 'react-hot-toast';
import { Password } from '@mui/icons-material';
import UploadCVHR from './pages/HrManagerJobs/UploadCVHR';
import HrJobsList from './pages/HrManagerJobs/HrJobsList';
import HrViewJob from './pages/HrManagerJobs/HrViewJob';
import AmViewJobs from './pages/AM_jobsUpload/AmViewJobs';
import AmJobsList from './pages/AM_jobsUpload/AmJobsList';
import UploadCVs from './pages/AccountManager/Job/UploadCVs';
import ViewAllJobReport from './pages/HrManagerJobs/ViewAllJobReport';
import JobReport from './pages/HrManagerJobs/JobReport';
import Associate from './pages/Assoicate/Associate';
import AssoiciateVeiwJob from './pages/Assoicate/AssoiciateVeiwJob';
import AddAndManageStatus from './pages/Status/AddAndManageStatus';
// import { useLocation } from "react-router-dom";

const Dashboard = () => {
  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, userProfileData } = useStateContext();
  console.log("jfdhgi475uihnfkld", userProfileData?._id)

  const [accountModal, setAccountModal] = useState(false);
  const [keySkill, setKeySkill] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const location = useLocation();

  const handleChange = e => {
    const { name, value } = e.target;

    if (name === 'oldPassword') {
      setOldPassword(value);
    } else if (name === 'newPassword') {
      setNewPassword(value);
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value);
    }
  };

  const ChangePassword = async e => {
    e.preventDefault();
    // alert("hii")
    // Basic password validation
    if (!oldPassword || !newPassword || !confirmPassword) {
      return toast.error('All fields are required');
    } else if (newPassword !== confirmPassword) {
      return toast.error('New password and confirm password do not match');
    } else if (newPassword.length < 5) {
      return toast.error('Password must be at least 5 characters');
    } else {

      let data = {
        oldPassword: oldPassword,
        newPassword: newPassword
      };

      let result = await HttpClient.requestData('update-password', 'PUT', data);
      if (result && result?.status) {
        toast.success('Password change successful');
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setAccountModal(false);
        closeModal()
      } else {
        toast.error("Old password not matched");
      }

    }
  };

  // const { userProfileData } = useStateContext();


  const hankclickModal = () => {
    setAccountModal(!accountModal);
  };

  const HandleSubmit = async e => {
    e.preventDefault();
    let data = {
      searchName: keySkill,
    };
    let res = await HttpClient?.requestData('search-resume', 'POST', data);
    navigate('/search-skill', { state: res?.data });
    setKeySkill('');
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '30em',
      height: '32em',
    },
  };

  function closeModal() {
    setIsOpen(false);
    // console.log('modal is close');
  }

  function openModal() {

    setIsOpen(true);
  }

  const logOut = () => {
    // alert("Are you really want to logout ?");
    reactLocalStorage.remove('adminData');
    reactLocalStorage.remove('loginStatus');
    navigate('/login');
  };

  const togglePasswordVisibility = () => {
    setShow(!show);
  };
  const togglePasswordVisibility1 = () => {
    setShow1(!show1);
  };
  const togglePasswordVisibility2 = () => {
    setShow2(!show2);
  };


  const navigate = useNavigate();

  return (
    <div onClick={() => {
      setIsOpen(false);
      // setAccountModal(false)
    }} className="flex relative dark:bg-main-dark-bg">
      <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}>
        <TooltipComponent content="Settings" position="TopCenter">
          <button
            type="button"
            style={{ borderRadius: '50%', backgroundColor: currentColor }}
            className="text-3xl p-3 text-white hover:drop-shadow-xl hover:bg-light-gray"
            onClick={() => setThemeSettings(true)}
          >
            <FiSettings />
          </button>
        </TooltipComponent>
      </div>
      {activeMenu ? (
        <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white">
          <Sidebar />
        </div>
      ) : (
        <div className="w-0 dark:bg-secondary-dark-bg">{/* <Sidebar /> */}</div>
      )}

      <div
        className={`dark:bg-main-dark-bg bg-main-bg min-h-screen w-full
              ${activeMenu ? 'md:ml-72' : ' flex-2'}
              `}
      >
        <div
          className="fixed md:static bg-main-bg dark:bg-main-dark-bg"
          style={{
            // zIndex: '-20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Navbar />

          {location?.pathname === '/' && (
            <form>
              <div className="input-group" style={{ flexWrap: 'nowrap' }}>
                <div className="form-outline w-10form" data-mdb-input-init>
                  <input
                    id="search-focus"
                    placeholder="Enter keyskill"
                    value={keySkill}
                    onChange={e => {
                      setKeySkill(e.target.value);
                    }}
                    type="search"
                    class="form-control outlinew_100"
                  />
                </div>
                <button type="submit" class="btn btn-primary" data-mdb-ripple-init onClick={e => HandleSubmit(e)}>
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </form>
          )}

          <div
            className="app-header__content"
            style={{ display: 'flex', flexDirection: 'coloum', justifyContent: 'flex-end', width: '26%' }}
          >
            <div className="app-header-right">
              <div className="header-btn-lg pe-0">
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left">
                      <div className="btn-group">
                        <button
                          style={{ display: 'flex', flexDirection: 'row' }}
                          type="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="p-0 btn btn-link"
                          onClick={(e) => {
                            // e.stopPropagation()
                            hankclickModal()
                          }}
                        >
                          <img
                            style={{ marginTop: '9px' }}
                            width={42}
                            className="rounded-circle"
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"
                            alt=""
                          />
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="angle-down"
                            className="svg-inline--fa fa-angle-down ms-2 opacity-8"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            style={{ height: '1em', marginTop: '29% ' }}
                          >
                            <path
                              fill="currentColor"
                              d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                            />
                          </svg>
                        </button>
                        <div
                          tabIndex={-1}
                          role="menu"
                          aria-hidden="true"
                          className="rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-end"
                        ></div>
                        <div style={{ padding: '12px' }}>
                          <div className="widget-heading" style={{ fontWeight: 'bold' }}>
                            {userProfileData?.fullName}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          {accountModal && (
            <div className="accountOverlay">
              <div
                tabIndex={-1}
                role="menu"
                aria-hidden="false"
                className="rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-end show"
                data-popper-placement="bottom-end"
                style={{
                  position: 'absolute',
                  inset: '38px 38px auto auto',
                  transform: 'translate(0px, 44px)',
                }}
              >
                <ul className="nav flex-column">
                  {/* <li className="nav-item">
                <a href="#" className="nav-link">
                  Edit Profile
                </a>
              </li> */}
                  {/* <p>Admin</p> */}

                  {/* <li className="nav-item-header nav-item">My Account</li> */}

                  {/* <li className="nav-item"> */}
                  <h5 style={{ textAlign: 'center' }}>{userProfileData?.fullName}</h5>
                  <hr style={{ margin: '0px' }} />
                  <a className="nav-link">
                    <div className="ms-auto badge bg-warning m-2" onClick={(e) => {
                      e.stopPropagation()
                      openModal()
                    }} style={{ cursor: 'pointer' }}>
                      Change password
                    </div>
                    <div className="ms-auto badge bg-warning" onClick={logOut} style={{ cursor: 'pointer' }}>
                      Logout
                    </div>
                  </a>
                  {/* </li> */}
                </ul>
              </div>
            </div>
          )}
          {/* onRequestClose={closeModal} */}
          <Modal isOpen={modalIsOpen} style={customStyles}>
            <div>
              <div onClick={(e) => e.stopPropagation()}
              >
                <div style={{ margin: '5px' }}>
                  <button
                    onClick={() => {
                      closeModal();
                    }}
                    className="btn btn-danger"
                  >
                    X
                  </button>
                  <form>
                    <div className="form-group" style={{ position: 'relative' }}>
                      <label for="formGroupExampleInput">Old Password</label>
                      <input
                        type={show ? 'text' : 'password'}
                        class="form-control"
                        id="formGroupExampleInput"
                        placeholder="Enter old password"
                        name="oldPassword"
                        value={oldPassword}
                        onChange={handleChange}
                      />
                      <div
                        style={{ position: 'absolute', top: '64px', left: '342px' }}
                        class="LoginEye"
                        onClick={togglePasswordVisibility}
                      >
                        {show ? <FaEyeSlash /> : <FaEye />}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="formGroupExampleInput2">New Password</label>
                      <input
                        type={show1 ? 'text' : 'password'}
                        className="form-control"
                        id="formGroupExampleInput2"
                        placeholder="Enter new password"
                        name="newPassword"
                        value={newPassword}
                        onChange={handleChange}
                      />
                      <div
                        style={{ position: 'absolute', top: '223px', left: '369px' }}
                        class="LoginEye"
                        onClick={togglePasswordVisibility1}
                      >
                        {show1 ? <FaEyeSlash /> : <FaEye />}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="formGroupExampleInput2">Confirm Password</label>
                      <input
                        type={show2 ? 'text' : 'password'}
                        className="form-control"
                        id="formGroupExampleInput2"
                        placeholder="Enter new confirm Password"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={handleChange}
                      />
                      <div
                        style={{ position: 'absolute', top: '326px', left: '370px' }}
                        class="LoginEye"
                        onClick={togglePasswordVisibility2}
                      >
                        {show2 ? <FaEyeSlash /> : <FaEye />}
                      </div>
                    </div>

                    <button type="button" onClick={e => ChangePassword(e)} class="btn btn-primary">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </Modal>

          {/* {themeSettings && <ThemeSettings />} */}
          <Routes>
            {/* dashboard  */}
            <Route path="/" element={<Ecommerce />} />
            {/* AssoiciateVeiwJob */}
            <Route path="/add-account-manager" element={<AddManager />} />
            <Route path="/add-account-recruiter" element={<AddRecruiter />} />
            <Route path="/add-account-associate" element={<Associate />} />
            <Route path="/view-account-associate" element={<AssoiciateVeiwJob />} />
            <Route path="/add-and-manage-job" element={<AddAndManageJob />} />
            <Route path="/hr-manager-jobs-list" element={<JobsList />} />
            <Route path="/hr-manager-view-jobs-list" element={<ViewJobList />} />
            <Route path="/hr-manager-view-cv" element={<ViewJob />} />
            <Route path="/jobs-list" element={<JobList />} />
            <Route path="/view-job-upload-CV" element={<ViewJobs />} />
            <Route path="/add-and-manage-recruiter" element={<AddAndManageRecruiter />} />
            <Route path="/add-and-manage-manager-job" element={<AddAndManageManagerJob />} />
            <Route path="/manager-jobs-list" element={<ViewAllManagerJob />} />
            <Route path="/search-skill" element={<SearchkeySkill />} />
            <Route path="/view-resume-reports" element={<ViewAllReports />} />
            <Route path="/view-jobs-reports" element={<ViewAllJobReport />} />

            <Route path="/upload-cv-by-hr" element={<UploadCVHR />} />

            <Route path="/am-job-list" element={<HrJobsList />} />
            <Route path="/am-view-job-upload-CV" element={<HrViewJob />} />

            <Route path="/hr-jobs-list" element={<AmJobsList />} />
            <Route path="/hr-view-job-upload-CV" element={<AmViewJobs />} />


            <Route path="/am-job-upload-CV" element={<UploadCVs />} />
            <Route path="/view-job" element={<JobReport />} />

            <Route path="/add-status" element={< AddAndManageStatus />} />

            {/* JobReport */}
          </Routes>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Dashboard;
