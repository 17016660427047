import React, { createContext, useContext, useEffect, useState } from "react";
import HttpClient from "../components/HttpClient";
import { toast } from "react-hot-toast";

const StateContext = createContext(null);

const initialState = {
  chat: false,
  cart: false,
  userProfile: false,
  notification: false,
};

export const ContextProvider = ({ children }) => {
  const [userProfileData , setUserProfileData] = useState("");
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState("#03C9D7");
  const [currentMode, setCurrentMode] = useState("Light");
  const [themeSettings, setThemeSettings] = useState(false);
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);
  const [recruiterDetails, setRecruiterDetails] = useState([]);
  const [ recruiterAssignJob , setRecruiterAssignJob] = useState(null)
  const [jobs,setJobs]=useState([]);

  // console.log('userProfileData', userProfileData);
  useEffect(() => {
    userProfile();
  },[])
  


  const userProfile = async() => {
    let response = await HttpClient.requestData('profile', 'GET');
    console.log("RESPONSE",response);
    if (response?.status) {
      setUserProfileData(response?.data);
    }
    //  else {
    //   toast.error("error fetching profile details");
    // }
  }

  const setMode = (e) => {
    setCurrentMode(e.target.value);
    localStorage.setItem("themeMode", e.target.value);
  };

  const setColor = (color) => {
    setCurrentColor(color);
    localStorage.setItem("colorMode", color);
  };

  const handleClick = (clicked) =>
    setIsClicked({ ...initialState, [clicked]: true });

  const getRecruiterDetails = async () => {
    let response = await HttpClient.requestData("recruiter", "GET");
    if (response?.status) {
      setRecruiterDetails(response?.data);
    } else if (response?.status === false) {
      toast.error("error fetching recruiter details");
    }
  };
  const getJobListing=async ()=>{
    let response = await HttpClient.requestData("job", "GET");
    if (response?.status) {
      setJobs(response?.data);
    } else if (response?.status === false) {
      toast.error("error fetching recruiter details");
    }

  }

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StateContext.Provider
      value={{
        currentColor,
        currentMode,
        activeMenu,
        screenSize,
        setScreenSize,
        handleClick,
        isClicked,
        initialState,
        setIsClicked,
        setActiveMenu,
        setCurrentColor,
        setCurrentMode,
        setMode,
        setColor,
        themeSettings,
        setThemeSettings,
        getRecruiterDetails,
        recruiterAssignJob , setRecruiterAssignJob,
        userProfileData,
        recruiterDetails,
        getJobListing,
        jobs
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
