import React, { useEffect, useState } from 'react'
import './JobList.css'
import HttpClient from '../../components/HttpClient';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import DataTable from 'react-data-table-component';

const Loader = () => {
  return (
    <div style={{ position: 'fixed', top: '25%', left: '40%', zIndex: 9999, fontSize: "25px", marginLeft: "20px" }}>
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

const JobList = () => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [jobsListData, setJobsListData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [singleData, setSingleData] = useState({});
  const [countCV, setCountCV] = useState([]);

  const { userProfileData, setRecruiterAssignJob } = useStateContext();

  console.log('userProfileD6546ata', jobsListData);  

  const columns = [
    {
      name: (
        <div
        >
          SL
        </div>
      ),
      selector: (row) => row.sl,
      sortable: true,
      center: true,
    },
    {
      name: (
        <div
        >
          Job Title
        </div>
      ),
      selector: (row) => row.jobTitle,
      center: true,
    },
    {
      name: (
        <div
        >
          Location
        </div>
      ),
      selector: (row) => row.jobLocation,
      center: true,
    },
    {
      name: (
        <div
        >
          CV Uploaded
        </div>
      ),
      selector: (row) => row.totalResumePosted,
      center: true,
    },
    // {
    //   name: (
    //     <div

    //     >
    //       Time
    //     </div>
    //   ),
    //   selector: (row) => row.time,
    //   center: true,
    // },
    // {
    //   name: (
    //     <div

    //     >
    //       Total CV
    //     </div>
    //   ),
    //   selector: (row) => row.totalCV,
    //   center: true,
    // },
    {
      name: (
        <div
        >
          Action
        </div>
      ),
      selector: (row) => row.action,
      center: true,
    },
  ];

  const fetchAllJobsList = async (id) => {
    // alert("hii")
    let res = await HttpClient.requestData('jobList', 'GET');
    console.log('RESPONSEfsfdgfgdfgdfgdfgdfgdfg', res?.data);
    let newArr = [];

    res?.data?.forEach((ele) => {

      ele?.assignedRecruiter?.forEach((element) => {
        if (element === id) {
          newArr.push(ele);
        }
      })
    })

    newArr && setRecruiterAssignJob(newArr?.length);
    if (res && res?.status) {

      let arr = newArr && newArr?.map((item, index) => {
        return {
          sl: index + 1,
          id: item?._id,
          jobTitle: item?.jobTitle,
          keySkills: item?.keySkills,
          jobLocation: item?.jobLocation,
          totalResumePosted: item?.ResumeData?.length,
          assignRecruiterName: item?.assignedRecruiterName,
          resumeId: item?.ResumeData[0]?._id,
          dataandtime: item?.createdAt,
          action: (
            <>
              <div className='right'>
                <button class="btn btn-outline-success eyeicn"
                  title="View CV"
                  // onClick={() => {
                  //   setModal(true); 
                  //   viewCVdata(item)
                  // }}
                  onClick={() => {
                    setModal(true);
                    viewCVdata(item)
                  }}
                  style={{
                    // fontWeight: 'bold',
                    marginLeft: "30px",
                    fontSize: '18px',
                  }}><i class="fa-solid fa-eye"></i>
                </button>
                <button className='mx-3'
                  onClick={() => handleCV(item)}
                >Upload CVs</button>
              </div>
            </>
          )
        };
      });
      setJobsListData(arr);
    }
    setLoading(false)
  };

  const handleCV = id => {
    console.log("handleCV", id)
    navigate('/view-job-upload-CV', { state: id });
    // getSingleJobDetails()
  };
  const handleView = id => {
    console.log("handleView", id)
    // navigate('/view-job-upload-CV', { state: id });
    // getSingleJobDetails()
  };

  const viewCVdata = (data) => {
    getSingleJobDetails(data?.id)
  }

  const getSingleJobDetails = async (id) => {
    setLoading(true);
    const res = await HttpClient.requestData(`job/${id}`, "GET");
    console.log("RESFDSFFDSFSF", res);
    if (res && res?.status) {
      console.log(res, "bhuguhjb");
      setSingleData(res?.data[0]);
      // res?.data[0]?.ResumesData[0] &&
      //   setUploadedCV(res?.data[0]?.ResumesData[0]?.resume);
      res?.data[0]?.ResumesData[0] &&
        setCountCV(res?.data[0]?.ResumesData[0]?.resume);

      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userProfileData) fetchAllJobsList(userProfileData?._id);
  }, [userProfileData]);



  return (
    <>
      {loading ? ( // Render loader if data is loading
        <Loader />
      ) : jobsListData && jobsListData?.length === 0 ? (
        <p>No data available</p>
      ) : (
        <>
          <div
            className=""
            style={{
              width: '100%',
              padding: '20px 20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
            }}
          >
            <div className="list">
              <DataTable columns={columns}
                data={jobsListData}
                pagination />
            </div>
          </div>

        </>

      )}
    </>


  );
}

export default JobList
