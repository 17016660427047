import React, { useEffect, useMemo, useState } from 'react';
import HttpClient from '../../../components/HttpClient';
// import '../pages/Login.css';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
const AddAndManageRecruiter = () => {
  const [userEmail, setUserEmail] = useState('');
  const [hide, setHide] = useState(true);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [pass, setPass] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passError, setPassError] = useState('');
  const [id, setId] = useState('');

  const [nameError, setnameError] = useState('');

  const [AcoountManagerData, setAccountManagerData] = useState([]);

  const navigate = useNavigate();

  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const emailValidation = () => {
    if (userEmail === '') {
      setEmailError('Email Field cannot be blank.');
    } else if (userEmail != '' && !userEmail.match(mailformat)) {
      setEmailError('Please enter valid email');
    } else {
      setEmailError('');
    }
  };

  const passwordValidation = () => {
    if (password === '') {
      setPassError('Password Field Cannot be blank');
    } else if (password != '' && password.length < 6) {
      setPassError('Password length must be 6 characters or more');
    } else {
      setPassError('');
    }
  };

  const nameValidation = () => {
    if (userName === '') {
      setnameError('User Name Field Cannot be blank');
    } else {
      setnameError('');
    }
  };

  const validation = useMemo(() => {
    if (
      userEmail != '' &&
      userName != '' &&
      password != '' &&
      userEmail.match(mailformat) &&
      password &&
      password.length >= 6 &&
      emailError == '' &&
      passError == '' &&
      nameError == ''
    ) {
      return true;
    } else {
      return false;
    }
  }, [userEmail, password, emailError, passError, userName, nameError]);

  const validation1 = useMemo(() => {
    if (
      userEmail != '' &&
      userName != '' &&
      // password != '' &&
      userEmail.match(mailformat) &&
      // password &&
      // password.length >= 6 &&
      emailError == '' &&
      // passError == '' &&
      nameError == ''
    ) {
      return true;
    } else {
      return false;
    }
  }, [userEmail, emailError, userName, nameError]);

  const onsubmit = async e => {
    e.preventDefault();

    let data = {
      userType: 'Recruiter',
      fullName: userName,
      email: userEmail,
      password: password,
    };

    if (validation) {
      let result = await HttpClient.requestData('registerBy', 'POST', data);
      if (result && result.status) {
        toast.success(result.message);
        fetchAllAccountManagerList();
        setUserEmail('');
        setPassword('');
        setUserName('');
      } else {
        toast.error(result.message);
      }
    } else {
      emailValidation();
      passwordValidation();
      nameValidation();
    }
  };

  const onUpdate = async e => {
    e.preventDefault();

    let data = {
      userType: 'Recruiter',
      fullName: userName,
      email: userEmail,
      password: password,
    };

    if (validation1) {
      let result = await HttpClient.requestData(`updateAMByHRProfile/${id}`, 'PUT', data);
      if (result && result.status) {
        toast.success(result.message);
        setHide(true);
        fetchAllAccountManagerList();
        setUserEmail('');
        setPassword('');
        setUserName('');
      } else {
        toast.error(result.message);
      }
    } else {
      emailValidation();
      // passwordValidation();
      nameValidation();
    }
  };

  useEffect(() => {
    fetchAllAccountManagerList();
  }, []);

  const fetchAllAccountManagerList = async () => {
    let res = await HttpClient.requestData('profileAdded', 'GET');
    console.log('RESPONSEghfhfghfgh', res);

    if (res && res?.status) {
      let arr = res?.data?.map((item, index) => {
        return {
          sl: index + 1,
          userType: item?.userType,
          name: item?.fullName,
          email: item?.email,
          action: (
            <div style={{ display: 'flex', flexDirection: 'coloum' }}>
              <svg
                onClick={e => onEdit(e, item)}
                style={{
                  height: '20px',
                  width: '20px',
                  cursor: 'pointer',
                  marginRight: '20px',
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-pencil-square"
                viewBox="0 0 16 16"
              >
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path
                  fill-rule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                />
              </svg>
              <svg
                onClick={() => onDelete(item?._id)}
                style={{
                  color: 'red',
                  height: '20px',
                  cursor: 'pointer',
                  width: '20px',
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-trash3"
                viewBox="0 0 16 16"
              >
                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
              </svg>
            </div>
          ),
        };
      });
      setAccountManagerData(arr);
    }
  };

  const onDelete = async id => {
    //  console.log('Id', id);

    Swal.fire({
      title: 'Are you sure?',
      // text: "You won't  to delete this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.isConfirmed) {
        HttpClient.requestData(`deleteAMByHRProfile/${id}`, 'DELETE')
          .then(res => {
            if (res && res.status) {
              toast.success('Deleted Successfully');
              fetchAllAccountManagerList();
            } else {
              toast.error('Failed to Delete the Item ');
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    });
  };

  const onEdit = (e, item) => {
    console.log('PASSSDSDF', item);
    e.preventDefault();
    setUserName(item?.fullName);
    setUserEmail(item?.email);
    setPassword(item?.plainPassword);
    setPass(item?.plainPassword);
    setId(item?._id);
    setHide(false);
  };

  const columns = [
    {
      name: <div style={{ fontSize: '14px', color: '#495057', fontWeight: 'bolder' }}>SL</div>,
      selector: row => row.sl,
      sortable: true,
      width: '80px',
    },
    {
      name: <div style={{ fontSize: '14px', color: '#495057', fontWeight: 'bolder' }}>User Type</div>,
      selector: row => row.userType,
    },
    {
      name: <div style={{ fontSize: '14px', color: '#495057', fontWeight: 'bolder' }}> Name</div>,
      selector: row => row.name,
    },
    {
      name: <div style={{ fontSize: '14px', color: '#495057', marginLeft: '15px', fontWeight: 'bolder' }}>Email</div>,
      selector: row => row.email,
    },
    {
      name: <div style={{ fontSize: '14px', color: '#495057', marginLeft: '15px', fontWeight: 'bolder' }}>Action</div>,
      selector: row => row.action,
    },
  ];

  return (
    <>
      <div style={{ width: '100%', padding: '20px 30px' }}>
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div
                style={{ textAlign: 'center', fontSize: '20px', color: '#868e96', margin: '35px' }}
                className="card-title"
              >
                {hide ? 'Add Recruiter' : 'Update Recruiter'}
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">
                  Full Name<span style={{ color: 'red' }}>*</span> :
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={userName}
                  onChange={e => setUserName(e.target.value)}
                  placeholder="Enter full name..."
                />
              </div>
              {nameError && <p className="error-login fade-in">{nameError}</p>}

              <div class="form-group">
                <label for="exampleInputEmail1">
                  Email<span style={{ color: 'red' }}>*</span> :
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={userEmail}
                  onChange={e => setUserEmail(e.target.value)}
                  placeholder="Enter userEmail..."
                />
              </div>
              {emailError && <p className="error-login fade-in">{emailError}</p>}
              {hide ? (
                <>
                  {' '}
                  <div class="form-group">
                    <label for="exampleInputEmail1">
                      Password<span style={{ color: 'red' }}>*</span> :
                    </label>
                    <input
                      type="password"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      placeholder="Enter password..."
                    />
                  </div>
                  {passError && <p className="error-login fade-in">{passError}</p>}
                </>
              ) : (
                pass && (
                  <div class="form-group">
                    <label for="exampleInputEmail1">Password : {pass}</label>
                  </div>
                )
              )}

              {hide ? (
                <button class="btn btn-primary" onClick={onsubmit}>
                  Submit
                </button>
              ) : (
                <button class="btn btn-primary" onClick={onUpdate}>
                  Update
                </button>
              )}

              <div
                style={{ textAlign: 'center', fontSize: '20px', color: '#868e96', margin: '35px' }}
                className="card-title"
              >
                View Recruiter List
              </div>
              <DataTable columns={columns} data={AcoountManagerData} pagination />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export default AddAndManageRecruiter