import React, { useState } from "react";
// import $ from "jquery";
import { Link } from "react-router-dom";
import { TbCategory } from "react-icons/tb";
import { useStateContext } from "../contexts/ContextProvider";
// import { VscDashboard  } from "react-icons/vsc";

function Sidebar() {
  const { userProfileData } = useStateContext();

  return (
    <>
      <div className="sidebar-mobile-overlay"> </div>
      <div
        className="app-sidebar bg-royal sidebar-text-light sidebar-shadow appear-done enter-done fade-in slide-right"
        id="sidebar"
      >
        <div className="logoImg" style={{ width: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img src='./favIcon.jpeg' className="img-fluid" alt="logo" style={{ width: "5rem", height: "5rem" }} />
        </div>

        <div className="app-sidebar__inner">
          <div className="metismenu vertical-nav-menu">
            <ul className="metismenu-container">
              <li className="metismenu-item">
                <Link className="metismenu-link" to="/" style={{ fontWeight: '700', fontSize: '18px' }}>
                  {/* <i class="metismenu-icon fa-solid fa-gauge"></i> */}
                  {/* <VscDashboard/> */}
                  Dashboard
                </Link>
              </li>
            </ul>
          </div>

          {userProfileData?.userType === 'Recruiter' && (
            <>
              <div className="panel-group" id="accordionMenu" role="tablist" aria-multiselectable="true"></div>
              <div className="panel-body">
                <div className="panel panel-default metismenu vertical-nav-menu">
                  <div className="panel-heading metismenu-container" role="tab" id="headingTens338">
                    <div className="panel-title metismenu-item">
                      <a
                        className="collapsed metismenu-link"
                        role="button"
                        data-toggle="collapse"
                        data-parent="#accordionMenu"
                        href="#collapseTens490"
                        aria-expanded="false"
                        aria-controls="collapseTens490"
                        style={{ fontWeight: '700', fontSize: 'medium' }}
                      >
                        Jobs
                      </a>
                    </div>
                  </div>
                  <div
                    id="collapseTens490"
                    className="panel-collapse collapse"
                    role="tabpanel"
                    aria-labelledby="headingTens338"
                  >
                    <div className="panel-body">
                      <ul className="metismenu-container">
                        <li className="metismenu-item">
                          <Link to="/jobs-list" className="metismenu-link">
                            Jobs List
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {userProfileData?.userType === 'Associate' && (
            <>
              <div className="panel-group" id="accordionMenu" role="tablist" aria-multiselectable="true"></div>
              <div className="panel-body">
                <div className="panel panel-default metismenu vertical-nav-menu">
                  <div className="panel-heading metismenu-container" role="tab" id="headingTens3389">
                    <div className="panel-title metismenu-item">
                      <a
                        className="collapsed metismenu-link"
                        role="button"
                        data-toggle="collapse"
                        data-parent="#accordionMenu"
                        href="#collapseTens4901"
                        aria-expanded="false"
                        aria-controls="collapseTens4901"
                        style={{ fontWeight: '700', fontSize: 'medium' }}
                      >
                        Upload CV
                      </a>
                    </div>
                  </div>
                  <div
                    id="collapseTens4901"
                    className="panel-collapse collapse"
                    role="tabpanel"
                    aria-labelledby="headingTens3389"
                  >
                    <div className="panel-body">
                      <ul className="metismenu-container">
                        <li className="metismenu-item">
                          <Link to="/view-account-associate" className="metismenu-link">
                            Add & Manage CV
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {userProfileData?.userType === 'HR' && (
            <>
              <div className="panel-group" id="accordionMenu" role="tablist" aria-multiselectable="true"></div>
              <div className="panel-body">
                <div className="panel panel-default metismenu vertical-nav-menu">
                  <div className="panel-heading metismenu-container" role="tab" id="headingTens3387">
                    <div className="panel-title metismenu-item">
                      <a
                        className="collapsed metismenu-link"
                        role="button"
                        data-toggle="collapse"
                        data-parent="#accordionMenu"
                        href="#collapseTens4909"
                        aria-expanded="false"
                        aria-controls="collapseTens4909"
                        style={{ fontWeight: '700', fontSize: 'medium' }}
                      >
                        Account Manager
                      </a>
                    </div>
                  </div>
                  <div
                    id="collapseTens4909"
                    className="panel-collapse collapse"
                    role="tabpanel"
                    aria-labelledby="headingTens3387"
                  >
                    <div className="panel-body">
                      <ul className="metismenu-container">
                        <li className="metismenu-item">
                          <Link to="/add-account-manager" className="metismenu-link">
                            Add & Manage Manager
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="panel-body">
                <div className="panel panel-default metismenu vertical-nav-menu">
                  <div className="panel-heading metismenu-container" role="tab" id="headingRecTens3387">
                    <div className="panel-title metismenu-item">
                      <a
                        className="collapsed metismenu-link"
                        role="button"
                        data-toggle="collapse"
                        data-parent="#accordionMenu"
                        href="#collapseRecTens4909"
                        aria-expanded="false"
                        aria-controls="collapseRecTens4909"
                        style={{ fontWeight: '700', fontSize: 'medium' }}
                      >
                        Recruiter
                      </a>
                    </div>
                  </div>
                  <div
                    id="collapseRecTens4909"
                    className="panel-collapse collapse"
                    role="tabpanel"
                    aria-labelledby="headingRecTens3387"
                  >
                    <div className="panel-body">
                      <ul className="metismenu-container">
                        <li className="metismenu-item">
                          <Link to="/add-account-recruiter" className="metismenu-link">
                            Add & Manage Recruiter
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="panel-body">
                <div className="panel panel-default metismenu vertical-nav-menu">
                  <div className="panel-heading metismenu-container" role="tab" id="headingRecTens33878">
                    <div className="panel-title metismenu-item">
                      <a
                        className="collapsed metismenu-link"
                        role="button"
                        data-toggle="collapse"
                        data-parent="#accordionMenu"
                        href="#collapseRecTens49099"
                        aria-expanded="false"
                        aria-controls="collapseRecTens49099"
                        style={{ fontWeight: '700', fontSize: 'medium' }}
                      >
                        Associate
                      </a>
                    </div>
                  </div>
                  <div
                    id="collapseRecTens49099"
                    className="panel-collapse collapse"
                    role="tabpanel"
                    aria-labelledby="headingRecTens33878"
                  >
                    <div className="panel-body">
                      <ul className="metismenu-container">
                        <li className="metismenu-item">
                          <Link to="/add-account-associate" className="metismenu-link">
                            Add & Manage Associate
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="panel-body">
                <div className="panel panel-default metismenu vertical-nav-menu">
                  <div className="panel-heading metismenu-container" role="tab" id="headingTens33871">
                    <div className="panel-title metismenu-item">
                      <a
                        className="collapsed metismenu-link"
                        role="button"
                        data-toggle="collapse"
                        data-parent="#accordionMenu"
                        href="#collapseTens49099"
                        aria-expanded="false"
                        aria-controls="collapseTens49099"
                        style={{ fontWeight: '700', fontSize: 'medium' }}
                      >
                        Status
                      </a>
                    </div>
                  </div>
                  <div
                    id="collapseTens49099"
                    className="panel-collapse collapse"
                    role="tabpanel"
                    aria-labelledby="headingTens33871"
                  >
                    <div className="panel-body">
                      <ul className="metismenu-container">
                        <li className="metismenu-item">
                          <Link to="/add-status" className="metismenu-link">
                            Add Status
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="panel-body">
                <div className="panel panel-default metismenu vertical-nav-menu">
                  <div className="panel-heading metismenu-container" role="tab" id="headingTens33871">
                    <div className="panel-title metismenu-item">
                      <a
                        className="collapsed metismenu-link"
                        role="button"
                        data-toggle="collapse"
                        data-parent="#accordionMenu"
                        href="#collapseTens49091"
                        aria-expanded="false"
                        aria-controls="collapseTens49091"
                        style={{ fontWeight: '700', fontSize: 'medium' }}
                      >
                        Job
                      </a>
                    </div>
                  </div>
                  <div
                    id="collapseTens49091"
                    className="panel-collapse collapse"
                    role="tabpanel"
                    aria-labelledby="headingTens33871"
                  >
                    <div className="panel-body">
                      <ul className="metismenu-container">
                        <li className="metismenu-item">
                          <Link to="/add-and-manage-job" className="metismenu-link">
                            Add & Manage Job
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="panel-group" id="accordionMenu" role="tablist" aria-multiselectable="true"></div>
              <div className="panel-body">
                <div className="panel panel-default metismenu vertical-nav-menu">
                  <div className="panel-heading metismenu-container" role="tab" id="headingTens33">
                    <div className="panel-title metismenu-item">
                      <a
                        className="collapsed metismenu-link"
                        role="button"
                        data-toggle="collapse"
                        data-parent="#accordionMenu"
                        href="#collapseTens49"
                        aria-expanded="false"
                        aria-controls="collapseTens49"
                        style={{ fontWeight: '700', fontSize: 'medium' }}
                      >
                        View Jobs
                      </a>
                    </div>
                  </div>
                  <div
                    id="collapseTens49"
                    className="panel-collapse collapse"
                    role="tabpanel"
                    aria-labelledby="headingTens33"
                  >
                    <div className="panel-body">
                      <ul className="metismenu-container">
                        <li className="metismenu-item">
                          <Link to="/hr-manager-jobs-list" className="metismenu-link">
                            Jobs List
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="panel-body">
                <div className="panel panel-default metismenu vertical-nav-menu">
                  <div className="panel-heading metismenu-container" role="tab" id="headingTens33">
                    <div className="panel-title metismenu-item">
                      <a
                        className="collapsed metismenu-link"
                        role="button"
                        data-toggle="collapse"
                        data-parent="#accordionMenu"
                        href="#collapseTens490"
                        aria-expanded="false"
                        aria-controls="collapseTens490"
                        style={{ fontWeight: '700', fontSize: 'medium' }}
                      >
                        Upload Jobs
                      </a>
                    </div>
                  </div>
                  <div
                    id="collapseTens490"
                    className="panel-collapse collapse"
                    role="tabpanel"
                    aria-labelledby="headingTens33"
                  >
                    <div className="panel-body">
                      <ul className="metismenu-container">
                        <li className="metismenu-item">
                          <Link to="/hr-jobs-list" className="metismenu-link">
                            Upload Jobs
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="panel-body">
                <div className="panel panel-default metismenu vertical-nav-menu">
                  <div className="panel-heading metismenu-container" role="tab" id="headingRecTens33876">
                    <div className="panel-title metismenu-item">
                      <a
                        className="collapsed metismenu-link"
                        role="button"
                        data-toggle="collapse"
                        data-parent="#accordionMenu"
                        href="#collapseRecTens49091"
                        aria-expanded="false"
                        aria-controls="collapseRecTens49091"
                        style={{ fontWeight: '700', fontSize: 'medium' }}
                      >
                        Report
                      </a>
                    </div>
                  </div>
                  <div
                    id="collapseRecTens49091"
                    className="panel-collapse collapse"
                    role="tabpanel"
                    aria-labelledby="headingRecTens33876"
                  >
                    <div className="panel-body">
                      <ul className="metismenu-container">
                        <li className="metismenu-item">
                          <Link to="/view-jobs-reports" className="metismenu-link">
                            Job
                          </Link>
                        </li>

                        <li className="metismenu-item">
                          <Link to="/view-resume-reports" className="metismenu-link">
                            Resume
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {userProfileData?.userType === 'AccountManager' && (
            <>
              <div className="panel-group" id="accordionMenu" role="tablist" aria-multiselectable="true"></div>

              <div className="panel-body">
                <div className="panel panel-default metismenu vertical-nav-menu">
                  <div className="panel-heading metismenu-container" role="tab" id="headingTens338713">
                    <div className="panel-title metismenu-item">
                      <a
                        className="collapsed metismenu-link"
                        role="button"
                        data-toggle="collapse"
                        data-parent="#accordionMenu"
                        href="#collapseTens490912"
                        aria-expanded="false"
                        aria-controls="collapseTens490912"
                        style={{ fontWeight: '700', fontSize: 'medium' }}
                      >
                        Recruiter
                      </a>
                    </div>
                  </div>
                  <div
                    id="collapseTens490912"
                    className="panel-collapse collapse"
                    role="tabpanel"
                    aria-labelledby="headingTens338713"
                  >
                    <div className="panel-body">
                      <ul className="metismenu-container">
                        <li className="metismenu-item">
                          <Link to="/add-and-manage-recruiter" className="metismenu-link">
                            Add & Manage Recruiter
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="panel-body">
                <div className="panel panel-default metismenu vertical-nav-menu">
                  <div className="panel-heading metismenu-container" role="tab" id="headingTens33871">
                    <div className="panel-title metismenu-item">
                      <a
                        className="collapsed metismenu-link"
                        role="button"
                        data-toggle="collapse"
                        data-parent="#accordionMenu"
                        href="#collapseTens49091"
                        aria-expanded="false"
                        aria-controls="collapseTens49091"
                        style={{ fontWeight: '700', fontSize: 'medium' }}
                      >
                        Job
                      </a>
                    </div>
                  </div>
                  <div
                    id="collapseTens49091"
                    className="panel-collapse collapse"
                    role="tabpanel"
                    aria-labelledby="headingTens33871"
                  >
                    <div className="panel-body">
                      <ul className="metismenu-container">
                        <li className="metismenu-item">
                          <Link to="/add-and-manage-manager-job" className="metismenu-link">
                            Add & Manage Job
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="panel-body">
                <div className="panel panel-default metismenu vertical-nav-menu">
                  <div className="panel-heading metismenu-container" role="tab" id="headingTens33871">
                    <div className="panel-title metismenu-item">
                      <a
                        className="collapsed metismenu-link"
                        role="button"
                        data-toggle="collapse"
                        data-parent="#accordionMenu"
                        href="#collapseTens490911"
                        aria-expanded="false"
                        aria-controls="collapseTens49091"
                        style={{ fontWeight: '700', fontSize: 'medium' }}
                      >
                        Upload Job
                      </a>
                    </div>
                  </div>
                  <div
                    id="collapseTens490911"
                    className="panel-collapse collapse"
                    role="tabpanel"
                    aria-labelledby="headingTens33870"
                  >
                    <div className="panel-body">
                      <ul className="metismenu-container"> 
                        <li className="metismenu-item">
                          <Link to="/am-job-list" className="metismenu-link">
                           Upload Job
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="panel-group" id="accordionMenu" role="tablist" aria-multiselectable="true"></div>
              <div className="panel-body">
                <div className="panel panel-default metismenu vertical-nav-menu">
                  <div className="panel-heading metismenu-container" role="tab" id="headingTens33">
                    <div className="panel-title metismenu-item">
                      <a
                        className="collapsed metismenu-link"
                        role="button"
                        data-toggle="collapse"
                        data-parent="#accordionMenu"
                        href="#collapseTens49"
                        aria-expanded="false"
                        aria-controls="collapseTens49"
                        style={{ fontWeight: '700', fontSize: 'medium' }}
                      >
                        View Jobs
                      </a>
                    </div>
                  </div>
                  <div
                    id="collapseTens49"
                    className="panel-collapse collapse"
                    role="tabpanel"
                    aria-labelledby="headingTens33"
                  >
                    <div className="panel-body">
                      <ul className="metismenu-container">
                        <li className="metismenu-item">
                          <Link to="/manager-jobs-list" className="metismenu-link">
                            Jobs List
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Sidebar;
