import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import "./Viewjobs.css";
import HttpClient from "../../components/HttpClient";
import { useLocation } from "react-router-dom";
import { useScrollTrigger } from "@mui/material";
import toast from "react-hot-toast";
import { IoMdDownload } from 'react-icons/io';
import DocViewer from "react-doc-viewer";

const SearchkeySkill = () => {
  const location = useLocation();
  const [cvData, setCvData] = useState([]);
 
  console.log('CVDATA', cvData);

  useEffect(() => {
    if (location?.pathname == '/search-skill') {
      setCvData(location?.state)
    }
  }, []);



 
  return (
    <>
      <h1 className="my-3">View CV List</h1>
      {cvData?.length == 0 ? (
        <img
          style={{ position: 'fixed', top: '10%', left: '40%' }}
          src="https://play-lh.googleusercontent.com/2RtouLIshDIrnpxQUsWjc9yJrRYxwcTiXfiljTRR-4K55cGERBF1q7rJNOuLKPe2kg"
        />
      ) : (
        <div
          className="row"
          // style={{display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap",}}
        >
          {cvData?.map((ele, index) => {
            return (
              <div className="col-lg-4 col-md-6 col-12">
                <div className=" oneDiv" style={{ margin: '0 20px 50px', backgroundColor: '#fff', padding: '0 20px' }}>
                  <div className="box">
                    <div
                      className=""
                      style={{
                        display: 'grid',
                        padding: '10px',
                      }}
                    >
                      <div style={{ position: 'relative', marginTop: '10px' }}>
                        <h1 style={{ color: '#000', fontSize: '24px' }}>{ele?.name}</h1>
                      </div>

                      <h5>
                        <span>Email :</span> {ele?.email}
                      </h5>
                      <h5>
                        <span>Location :</span> {ele?.location}
                      </h5>
                      <h5>
                        <span>PhoneNo :</span> {ele?.phoneNo}
                      </h5>
                      <h5>
                        <span>Experience :</span> {ele?.experience}
                      </h5>
                      <h5>
                        <span>Skills :</span> {ele?.skills?.join(', ')}
                      </h5>
                    </div>
                  </div>

                  <div className="box">
                    <div className="right">
                      <button
                        type="button"
                        style={{
                          fontWeight: 'bold',
                          fontSize: '20px',
                        }}
                        class="btn btn-outline-success"
                      >
                        <Link to={ele?.resume}>
                          Download CV <IoMdDownload />
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {/* {show && docFile && (<iframe src={docFile} width="800" height="600" style="border: 1px solid #ccc;"></iframe>)} */}
    </>
  );
};


export default SearchkeySkill